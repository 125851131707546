import React from 'react';
import classes from './ProgressBar.module.css';
import {brightTurquoiseColor, violetColor} from '../../../utils/constants';
import PropTypes from 'prop-types';

function ProgressBar({step}) {

  return (
    <div
      className={classes.progressBar}
      style={{display: `${step === 4 && 'none'}`}}
    >
      <div
        className={classes.progressBarItem}
        style={{background: `${step > 0 ? brightTurquoiseColor : violetColor}`}}
      />
      <div
        className={classes.progressBarItem}
        style={{background: `${step > 1 ? brightTurquoiseColor : violetColor}`}}
      />
      <div
        className={classes.progressBarItem}
        style={{background: `${step > 2 ? brightTurquoiseColor : violetColor}`}}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number
}

export default ProgressBar;