import React, {useEffect, useState} from 'react';
import FirstName from './FirstName';
import LastName from './LastName';
import ProgressBar from '../common/ProgressBar/ProgressBar';
import HeroesSlider from './HeroSlider/HeroesSlider';
import {useDispatch, useSelector} from 'react-redux';
import {loginUserRequest, registerUserRequest} from '../../store/actions/auth.actions';
import Loading from '../common/Loading/Loading';
import EntranceType from './EntranceType/EntranceType';
import {useHistory} from 'react-router-dom';
import {entranceTypeConstants} from '../../utils/constants';
import Error from './Error/Error';
import Cookie from 'js-cookie';
import styled from 'styled-components';
import {assignSessionRequest} from '../../store/actions/qr.actions';
import {checkPhoneNumber} from '../../store/actions/phoneNumber.actions';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
`

function LoginOrRegister() {

  let history = useHistory();

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  const qrData = useSelector(state => state.qrReducer.qrData);

  const sessionAssigned = useSelector(state => state.qrReducer.sessionAssigned);

  const phoneNumberAlreadyExists = useSelector(state => state.phoneNumberReducer.phoneNumberAlreadyExists)

  // for qr-code assignment for a new qr-code
  useEffect(() => {
    if(isLoggedIn && qrData){
      const token = Cookie.get('AUTH-TOKEN');
      dispatch(assignSessionRequest({token, qrData}))
    }
  }, [qrData, isLoggedIn])

  // for login via new qr-code that has just been assigned to a user
  useEffect(() => {
    if (sessionAssigned) {
      const token = Cookie.get('AUTH-TOKEN');
      dispatch(checkPhoneNumber(token))
      if (phoneNumberAlreadyExists === true){
        history.push('/home')
      } else if (phoneNumberAlreadyExists === false){
        history.push('/phone-number')
      }
    }
  }, [sessionAssigned, phoneNumberAlreadyExists])

  // for login via browser, without qr-code (no session assignment required)
  useEffect(() => {
    if (isLoggedIn && !qrData) {
      history.push('/home')
    }
  }, [isLoggedIn, qrData])


  const [step, setStep] = useState(0);
  const [entranceType, setEntranceType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(firstName && lastName && avatar){
      const requestBody = {name: firstName, lastName, avatar}
      if(entranceType === entranceTypeConstants.loginAsHero) {
        loginUser(requestBody)
      } else if(entranceType === entranceTypeConstants.createAHero) {
        registerUser(requestBody)
      }
      setIsFetching(true)
      setEntranceType('')
      setFirstName('')
      setLastName('')
      setAvatar('')
    }
  }, [firstName, lastName, avatar]);

  const loginUser = (requestBody) => {
    dispatch(loginUserRequest(requestBody))
  }

  const registerUser = (requestBody) => {
    dispatch(registerUserRequest(requestBody))
  }

  const error = useSelector(state => state.authReducer.showError)

  useEffect(() => {
    error && setStep(4)
    error && setIsFetching(false)
  }, [error])

  return (
    <StyledWrapper>
      {step === 0 && (
        <EntranceType
          setEntranceType={setEntranceType}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 1 && (
        <FirstName
          entranceType={entranceType}
          firstName={firstName}
          setEntranceType={setEntranceType}
          setFirstName={setFirstName}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 2 && (
        <LastName
          entranceType={entranceType}
          lastName={lastName}
          setLastName={setLastName}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 3 && (
        <HeroesSlider
          entranceType={entranceType}
          setAvatar={setAvatar}
          setStep={setStep}
          step={step}
        />
      )}
      {isFetching && (
        <Loading/>
      )}
      {step === 4 && error &&
          <Error setStep={setStep}/>
      }
      <ProgressBar
        setStep={step}
        step={step}
      />
    </StyledWrapper>
  );
}

export default LoginOrRegister;
