import bigHero1 from '../../images/big-heroes/hero1.png'
import bigHero2 from '../../images/big-heroes/hero2.png';
import bigHero3 from '../../images/big-heroes/hero3.png';
import bigHero4 from '../../images/big-heroes/hero4.png';
import bigHero5 from '../../images/big-heroes/hero5.png';
import bigHero6 from '../../images/big-heroes/hero6.png';
import bigHero7 from '../../images/big-heroes/hero7.png';
import bigHero8 from '../../images/big-heroes/hero8.png';
import bigHero9 from '../../images/big-heroes/hero9.png';
import bigHero10 from '../../images/big-heroes/hero10.png';
import bigHero11 from '../../images/big-heroes/hero11.png';
import bigHero12 from '../../images/big-heroes/hero12.png';
import bigHero13 from '../../images/big-heroes/hero13.png';
import bigHero14 from '../../images/big-heroes/hero14.png';
import bigHero15 from '../../images/big-heroes/hero15.png';
import bigHero16 from '../../images/big-heroes/hero16.png';
import bigHero17 from '../../images/big-heroes/hero17.png';
import bigHero18 from '../../images/big-heroes/hero18.png';
import bigHero19 from '../../images/big-heroes/hero19.png';
import bigHero20 from '../../images/big-heroes/hero20.png';

import smallHero1 from '../../images/small-heroes/hero1.png';
import smallHero2 from '../../images/small-heroes/hero2.png';
import smallHero3 from '../../images/small-heroes/hero3.png';
import smallHero4 from '../../images/small-heroes/hero4.png';
import smallHero5 from '../../images/small-heroes/hero5.png';
import smallHero6 from '../../images/small-heroes/hero6.png';
import smallHero7 from '../../images/small-heroes/hero7.png';
import smallHero8 from '../../images/small-heroes/hero8.png';
import smallHero9 from '../../images/small-heroes/hero9.png';
import smallHero10 from '../../images/small-heroes/hero10.png';
import smallHero11 from '../../images/small-heroes/hero11.png';
import smallHero12 from '../../images/small-heroes/hero12.png';
import smallHero13 from '../../images/small-heroes/hero13.png';
import smallHero14 from '../../images/small-heroes/hero14.png';
import smallHero15 from '../../images/small-heroes/hero15.png';
import smallHero16 from '../../images/small-heroes/hero16.png';
import smallHero17 from '../../images/small-heroes/hero17.png';
import smallHero18 from '../../images/small-heroes/hero18.png';
import smallHero19 from '../../images/small-heroes/hero9.png';
import smallHero20 from '../../images/small-heroes/hero20.png';

import crown1 from '../../images/heroez-with-crowns/hero1-with-crown.png'
import crown2 from '../../images/heroez-with-crowns/hero2-with-crown.png'
import crown3 from '../../images/heroez-with-crowns/hero3-with-crown.png'
import crown4 from '../../images/heroez-with-crowns/hero4-with-crown.png'
import crown5 from '../../images/heroez-with-crowns/hero5-with-crown.png'
import crown6 from '../../images/heroez-with-crowns/hero6-with-crown.png'
import crown7 from '../../images/heroez-with-crowns/hero7-with-crown.png'
import crown8 from '../../images/heroez-with-crowns/hero8-with-crown.png'
import crown9 from '../../images/heroez-with-crowns/hero9-with-crown.png'
import crown10 from '../../images/heroez-with-crowns/hero10-with-crown.png'
import crown11 from '../../images/heroez-with-crowns/hero11-with-crown.png'
import crown12 from '../../images/heroez-with-crowns/hero12-with-crown.png'
import crown13 from '../../images/heroez-with-crowns/hero13-with-crown.png'
import crown14 from '../../images/heroez-with-crowns/hero14-with-crown.png'
import crown15 from '../../images/heroez-with-crowns/hero15-with-crown.png'
import crown16 from '../../images/heroez-with-crowns/hero16-with-crown.png'
import crown17 from '../../images/heroez-with-crowns/hero17-with-crown.png'
import crown18 from '../../images/heroez-with-crowns/hero18-with-crown.png'
import crown19 from '../../images/heroez-with-crowns/hero19-with-crown.png'
import crown20 from '../../images/heroez-with-crowns/hero20-with-crown.png'

const initialState = {
  heroes: [
    {name: 'hero1', bigHero: bigHero1, smallHero: smallHero1, crown: crown1 },
    {name: 'hero2', bigHero: bigHero2, smallHero: smallHero2, crown: crown2 },
    {name: 'hero3', bigHero: bigHero3, smallHero: smallHero3, crown: crown3 },
    {name: 'hero4', bigHero: bigHero4, smallHero: smallHero4, crown: crown4 },
    {name: 'hero5', bigHero: bigHero5, smallHero: smallHero5, crown: crown5 },
    {name: 'hero6', bigHero: bigHero6, smallHero: smallHero6, crown: crown6 },
    {name: 'hero7', bigHero: bigHero7, smallHero: smallHero7, crown: crown7 },
    {name: 'hero8', bigHero: bigHero8, smallHero: smallHero8, crown: crown8 },
    {name: 'hero9', bigHero: bigHero9, smallHero: smallHero9, crown: crown9 },
    {name: 'hero10', bigHero: bigHero10, smallHero: smallHero10, crown: crown10 },
    {name: 'hero11', bigHero: bigHero11, smallHero: smallHero11, crown: crown11 },
    {name: 'hero12', bigHero: bigHero12, smallHero: smallHero12, crown: crown12 },
    {name: 'hero13', bigHero: bigHero13, smallHero: smallHero13, crown: crown13 },
    {name: 'hero14', bigHero: bigHero14, smallHero: smallHero14, crown: crown14 },
    {name: 'hero15', bigHero: bigHero15, smallHero: smallHero15, crown: crown15 },
    {name: 'hero16', bigHero: bigHero16, smallHero: smallHero16, crown: crown16 },
    {name: 'hero17', bigHero: bigHero17, smallHero: smallHero17, crown: crown17 },
    {name: 'hero18', bigHero: bigHero18, smallHero: smallHero18, crown: crown18 },
    {name: 'hero19', bigHero: bigHero19, smallHero: smallHero19, crown: crown19 },
    {name: 'hero20', bigHero: bigHero20, smallHero: smallHero20, crown: crown20 }
  ]
}

export const heroesReducer = (state = initialState, action) => {
  switch(action.type){
    default:
      return state
  }
}