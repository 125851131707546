import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import HeroesSliderTheme from '../../../themes/HeroesSliderTheme/HeroesSliderTheme';

function HeroesSliderMobile({step, setStep, setAvatar}) {

  const allHeroes = useSelector(state => state.heroesReducer.heroes)

  const [selectedImg, setSelectedImg] = useState(0);

  const [pickedHero, setPickedHero] = useState('');

  const [error, setError] = useState(false);

  const nextSlide = () => {
    if (selectedImg === 19) {
      setSelectedImg(0);
    } else {
      setSelectedImg(selectedImg + 1);
    }
  };

  const prevSlide = () => {
    if (selectedImg === 0) {
      setSelectedImg(19);
    } else {
      setSelectedImg(selectedImg - 1);
    }
  };

  const handleBackBtn = () => {
    setStep(step - 1);
  };

  const handleNextBtn = () => {
    if (pickedHero !== '') {
      setAvatar(allHeroes[pickedHero].name)
    } else {
      setError(true)
    }
  };

  const pickAvatar = (index) => {
    error && setError(false)
    setPickedHero(index);
  }

  const style = (index) => ( {
    width: `${index === selectedImg ? '100%' : 0}`,
    marginLeft: `${index === selectedImg ? '25%' : 0}`,
    marginRight: `${index === selectedImg ? '25%' : 0}`,
    height: `${index === selectedImg ? '90%' : 0}`,
    display: `${index === selectedImg ? 'block' : 'none'}`,
  })

  return (
    <HeroesSliderTheme
      allHeroes={allHeroes}
      handleBackBtn={handleBackBtn}
      handleNextBtn={handleNextBtn}
      nextSlide={nextSlide}
      pickAvatar={pickAvatar}
      pickedHero={pickedHero}
      prevSlide={prevSlide}
      style={style}
    />
  );
}

HeroesSliderMobile.propTypes = {
  setAvatar: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

export default HeroesSliderMobile;
