import {call, put, takeEvery} from '@redux-saga/core/effects';
import {qrApi} from '../../api/api';
import {
  ASSIGN_SESSION_REQUEST,
  assignSessionSuccess,
  CHECK_QR_DATA,
  redirectToLoginPage,
} from '../actions/qr.actions';
import {loginUserSuccess, showError} from '../actions/auth.actions';
import Cookie from 'js-cookie';

export function* qrSaga() {
  yield takeEvery(CHECK_QR_DATA, function* (action) {
    try {
      const response = yield call(qrApi.loginQR, action.payload)
      const token = `Bearer ${response.data?.split(' ')[1]}`
      Cookie.set('AUTH-TOKEN', token)
      yield put(loginUserSuccess())
    }
    catch (error) {
      yield put(redirectToLoginPage())
    }
  })
  yield takeEvery(ASSIGN_SESSION_REQUEST, function* (action) {
    try {
      yield call(qrApi.assignSession, action.payload)
      yield put(assignSessionSuccess())
    } catch (error) {
      yield put(showError(error.response))
    }
  })
}