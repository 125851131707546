import React, {useEffect, useState} from 'react';
import classes from './PhoneNumberPath.module.css';
import {violetColor} from '../../utils/constants';
import arrowLeft from '../../images/arrow-left.png';
import arrowRight from '../../images/arrow-right.png';
import PropTypes from 'prop-types';
import {confirmationCodeRegex, phoneNumberRegex} from '../../utils/regex';
import {
  confirmationCodeRequest,
  assignPhoneNumberRequest,
  resetPhoneNumber
} from '../../store/actions/phoneNumber.actions';
import {useDispatch, useSelector} from 'react-redux';
import Cookie from 'js-cookie';

function ConfirmationCode({setStep, step}) {

  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [wrongCode, setWrongCode] = useState(false)
  const confirmationCodeSuccess = useSelector(state => state.phoneNumberReducer.confirmationCodeSuccess)

  const handleBackBtn = () => {
    setStep(step - 1);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPhoneNumber())
  }, [])

  const handleNextBtn = () => {
    setTouched(true)
    if(confirmationCodeRegex(confirmationCode)){
      const token = Cookie.get('AUTH-TOKEN');
      dispatch(confirmationCodeRequest({confirmationCode, token}));
    } else {
      setError(true)
    }
  }

  const handleChange = (e) => {
    setConfirmationCode(e.target.value)
  }

  useEffect(() => {
    if(touched && !confirmationCodeRegex(confirmationCode)) {
      setError(true)
    } else {
      setError(false)
    }
  }, [confirmationCode])

  useEffect(() => {
    if (confirmationCodeSuccess === true){
      setStep(step + 1);
    } else if (confirmationCodeSuccess === false){
      setWrongCode(true)
    }
  }, [confirmationCodeSuccess]);


  return (
    <>
      <div className={classes.rewardHeader}>
        COLLECT REWARD
      </div>
      <div className={classes.rewardSubheader}>
        ENTER CONFIRMATION CODE FROM TEXT MESSAGE.
      </div>
      <input
        className={classes.phoneField}
        onChange={handleChange}
        placeholder="0 0 0 0"
        style={{borderColor: `${touched && error ? 'red' : violetColor}`}}
        type="text"
        value={confirmationCode}
      />
      <div className={classes.codeError}>
        {wrongCode && 'WRONG CONFIRMATION CODE'}
      </div>
      <div
        className={classes.buttonsWrapper}
      >
        <button
          className={classes.backButton}
          onClick={handleBackBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow left'}
              className={classes.arrowInBtn}
              src={arrowLeft}
            />
            <div>BACK</div>
          </div>
        </button>
        <button
          className={classes.continueButton}
          onClick={handleNextBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow right'}
              className={classes.arrowInBtn}
              src={arrowRight}
            />
            <div>Next</div>
          </div>
        </button>
      </div>
    </>
  );
}

ConfirmationCode.propTypes = {
  setStep: PropTypes.func,
  step: PropTypes.number
}

export default ConfirmationCode;