import React from 'react';
import classes from './Header.module.css';
import JumpHeroezLogo from '../../../../images/JUMPILOT_LOGO.png';
import logoutIcon from '../../../../images/logout.png';
import rewardIcon from '../../../../images/reward-icon.png'
import Cookie from 'js-cookie'
import {useHistory} from 'react-router-dom';
import {cleaUserData} from '../../../../store/actions/dashboard.actions';
import {useDispatch} from 'react-redux';
import {logout} from '../../../../store/actions/auth.actions';
import {resetQR} from '../../../../store/actions/qr.actions';

function Header() {

  let history = useHistory();

  const dispatch = useDispatch();

  const handleLogout = () => {
    Cookie.remove('AUTH-TOKEN')
    dispatch(cleaUserData())
    dispatch(resetQR())
    dispatch(logout())
    history.push('/')
  }

  const showReward = () => {
    history.push('/rewards')
  }

  const goToEndOfJumpHeroez = () => {
    history.push('/end-of-jump-heroez')
  }

  return (
    <div className={classes.header}>
      <div className={classes.logoContainer}>
        <img
          alt={'JumpHeroez Logo'}
          className={classes.JumpHeroezLogo}
          src={JumpHeroezLogo}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <button
          className={classes.rewardButton}
          onClick={showReward}
        >
          <img
            alt={'Reward30Min Icon'}
            className={classes.rewardIcon}
            src={rewardIcon}
          />
          REWARD
        </button>
        <button
          className={classes.shareButton}
          onClick={goToEndOfJumpHeroez}
        >
          SHARE/INVITE
        </button>
        <button
          className={classes.logoutButton}
          onClick={handleLogout}
        >
          <img
            alt={'logout icon'}
            className={classes.logoutIcon}
            src={logoutIcon}
          />
          <div>LOG OUT</div>
        </button>
      </div>
    </div>
  );
}

export default Header;