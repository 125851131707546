import React from 'react';
import classes from './KingOfTheDay.module.css';
import {useSelector} from 'react-redux';
import {addSpacesInNumbers} from '../../../../../utils/addSpacesInNumbers';

function KingOfTheDay() {

  const bestOfTheDayScore = useSelector(state => state.dashboardReducer.dashboardData.bestOfTheDayScore)
  const bestUser = bestOfTheDayScore.user

  const kingOfTheDayName = bestUser &&  bestOfTheDayScore.user?.name
  const points = bestUser && addSpacesInNumbers(bestOfTheDayScore.userScore?.finalResult)
  const jumps = bestUser && addSpacesInNumbers(bestOfTheDayScore.userScore?.jumps)
  const airTime = bestUser && addSpacesInNumbers(Math.round(bestOfTheDayScore.userScore?.airTime / 1000))
  const heroIcons = useSelector(state => state.heroesReducer.heroes)
  const heroIcon = bestUser && heroIcons.find(icon => icon.name === bestOfTheDayScore.user.avatar).crown

  return (
    <>
      <div className={classes.kingOfTheDayWrapper}>
        {!bestUser
          ?  <div className={classes.kingOfTheDayHeader}>You are the first!</div>
          : 
          <>
            <div className={classes.kingOfTheDayHeader}>KING OF THE DAY</div>
            <div className={classes.kingOTheDayTable}>
              <div className={classes.kingOTheDayFirstRow}>
                <div className={classes.iconCell}>
                  <img
                    alt={'hero'}
                    className={classes.iconWithCrown}
                    src={heroIcon}
                  />
                </div>
                <div className={classes.kingOTheDayFirstRowCell}>NAME</div>
                <div className={classes.kingOTheDayFirstRowCell}>POINTS</div>
                <div className={classes.kingOTheDayFirstRowCell}>JUMPS</div>
                <div className={classes.kingOTheDayFirstRowCell}>AIRTIME</div>
              </div>
              <div className={classes.kingOTheDaySecondRow}>
                <div className={classes.kingOfTheDayVioletCell} />
                <div className={classes.kingOfTheDayVioletCell}>
                  <div className={classes.kingOfTheDayName}>
                    {kingOfTheDayName}
                  </div>
                </div>
                <div className={classes.kingOfTheDayCell}>
                  {points}
                </div>
                <div className={classes.kingOfTheDayCell}>
                  {jumps}
                </div>
                <div className={classes.kingOfTheDayCell}>
                  {airTime}
                  <span>"</span>
                </div>
              </div>
            </div>
          </>
        }
      </div> 
    </>
  );
}

export default KingOfTheDay;