import React from 'react';
import classes from './UserInfoHeader.module.css';
import jumpsIcon from '../../../../images/jumps-icon.png'
import pointsIcon from '../../../../images/points-icon.png'
import airTimeIcon from '../../../../images/airtime-icon.png'
import {useSelector} from 'react-redux';
import {addSpacesInNumbers} from '../../../../utils/addSpacesInNumbers';

function UserInfoHeader() {

  const heroIcons = useSelector(state => state.heroesReducer.heroes)

  const dashboardData = useSelector(state => state.dashboardReducer.dashboardData)

  const heroIcon = dashboardData && heroIcons.find(icon => icon.name === dashboardData.user.avatar).smallHero
  const userScore = dashboardData.userScore
  const points = userScore ? addSpacesInNumbers(dashboardData.userScore.finalResult) : 0
  const jumps = userScore ? addSpacesInNumbers(dashboardData.userScore.jumps) : 0
  const airTime = userScore ? addSpacesInNumbers(Math.round( dashboardData.userScore.airTime/ 1000)) : 0

  return (
    <div className={classes.userInfoWrapper}>
      <div className={classes.userName}>
        {dashboardData.user.name} {dashboardData.user.lastName}
      </div>
      <div className={classes.userDataWrapper}>
        <div className={classes.userDataCell}>
          <img
            alt={'hero icon'}
            className={classes.heroImg}
            src={heroIcon}
          />
        </div>
        <div className={classes.userDataCell}>
          <img
            alt={'points icon'}
            className={classes.pointsIcon}
            src={pointsIcon}
          />
          <div className={classes.word}>POINTS</div>
          <div className={classes.number}>
            {points}
          </div>
        </div>
        <div className={classes.userDataCell}>
          <img
            alt={'points icon'}
            className={classes.pointsIcon}
            src={jumpsIcon}
          />
          <div className={classes.word}>JUMPS</div>
          <div className={classes.number}>
            {jumps}
          </div>
        </div>
        <div className={classes.userDataCell}>
          <img
            alt={'points icon'}
            className={classes.pointsIcon}
            src={airTimeIcon}
          />
          <div className={classes.word}>AIRTIME</div>
          <div className={classes.number}>
            {airTime}"
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfoHeader;