import React from 'react';
import classes from './MainSectionMobile.module.css';
import LevelSection from './LevelSection/LevelSection';
import RewardSection from './RewardSection/RewardSection';
import KingOfTheDay from './KingOfTheDay/KingOfTheDay';


function MainSectionMobile() {
  return (
    <div className={classes.resultsWrapper}>
      <LevelSection/>
      <RewardSection/>
      <KingOfTheDay/>
    </div>

  );
}

export default MainSectionMobile;
