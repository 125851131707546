import {authSaga} from './auth.saga';
import { all } from 'redux-saga/effects';
import {dashboardSaga} from './dashboard.saga';
import {qrSaga} from './qr.saga';
import {phoneNumberSaga} from './phoneNumber.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    dashboardSaga(),
    qrSaga(),
    phoneNumberSaga()
  ])}