function withLayout(Layout, Component){
  return( props =>
    <Layout >
      <Component
        {...props}
      />
    </Layout>
  )
}

export default withLayout;