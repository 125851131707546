import {HIDE_ERROR, LOGIN_USER_SUCCESS, LOGOUT, SHOW_ERROR} from '../actions/auth.actions';

const initialState = {
  isLoggedIn: false,
  showError: false,
  errorText: false
}

export const authReducer = (state = initialState, action) => {
  switch(action.type){
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    case SHOW_ERROR:
      return {
        ...state,
        showError: true,
        errorText: action.payload
      }
    case HIDE_ERROR:
      return {
        ...state,
        showError: false,
        errorText: ''
      }
    default:
      return state
  }
}