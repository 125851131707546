export const CHECK_QR_DATA = 'CHECK_QR_DATA'
export const REDIRECT_TO_LOGIN_PAGE = 'REDIRECT_TO_LOGIN'
export const SET_QR_DATA = 'SET_QR_DATA'
export const ASSIGN_SESSION_REQUEST = 'ASSIGN_SESSION'
export const ASSIGN_SESSION_SUCCESS = 'ASSIGN_SESSION_SUCCESS'
export const SET_RETURN_USER = 'SET_RETURN_USER'
export const RESET_QR = 'RESET_QR'

export const setQRData = (body) => {
  return {
    type: SET_QR_DATA,
    payload: body
  }
}

export const checkQRData = (body) => {
  return {
    type: CHECK_QR_DATA,
    payload: body
  }
}

export const redirectToLoginPage = () => {
  return {
    type: REDIRECT_TO_LOGIN_PAGE
  }
}

export const assignSessionRequest = (body) => {
  return {
    type: ASSIGN_SESSION_REQUEST,
    payload: body
  }
}

export const assignSessionSuccess = () => {
  return {
    type: ASSIGN_SESSION_SUCCESS
  }
}

export const resetQR = () => {
  return {
    type: RESET_QR
  }
}
