import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {errorConstants} from '../../../utils/constants';
import classes from './Error.module.css'
import arrowLeft from '../../../images/arrow-left.png';
import {useHistory} from 'react-router-dom';
import {hideError, logout} from '../../../store/actions/auth.actions';
import PropTypes from 'prop-types';
import errorIcon from '../../../images/error-icon.png'

function Error({setStep}) {

  const errorText = useSelector(state => state.authReducer.errorText)

  const history = useHistory();

  const dispatch = useDispatch();

  const handleBackBtn = () => {
    dispatch(hideError())
    dispatch(logout())
    setStep(0)
    history.push('/')
  }

  const errorMessage = () => {
    if(errorText === errorConstants.alreadyExists){
      return (
        <div className={classes.errorMessage}>
          USER WITH THIS NAME ALREADY EXISTS.
        </div>
      )
    }
    else if (errorText.status === 401){
      return (
        <div className={classes.errorMessage}>
          TRY AGAIN. CHECK YOUR AVATAR.
        </div>
      )
    }
    else {
      return(
        <div className={classes.errorMessage}>
          <div>TRY AGAIN.</div>
          FIRST NAME, LAST NAME OR AVATAR IS WRONG.
        </div>
      )
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.firstColumn}>
        <img
          alt={'ERROR!'}
          className={classes.errorImg}
          src={errorIcon}
        />
      </div>
      <div className={classes.secondColumn}>
        {errorMessage()}
        <button
          className={classes.backButton}
          onClick={handleBackBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow in button'}
              className={classes.arrowInBtn}
              src={arrowLeft}
            />
            <div>BACK TO LOGIN</div>
          </div>
        </button>
      </div>
    </div>
  );
}

Error.propTypes = {
  setStep: PropTypes.func,
}

export default Error;