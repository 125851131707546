import React from 'react';
import classes from './RewardSection.module.css';
import openedChest from '../../../../../images/opened-chest.png';
import chest from '../../../../../images/chest.png';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

function RewardSection() {

  const dashboardData = useSelector(state => state.dashboardReducer.dashboardData)

  const history = useHistory()

  const goToEndOfJumpHeroez = () => {
    history.push('/end-of-jump-heroez')
  }

  return (
    <div className={classes.rewardsSectionWrapper}>

      <button
        className={classes.rewardWrapper}
        onClick={goToEndOfJumpHeroez}
      >
        <img
          alt={'opened chest'}
          className={classes.openedChest}
          src={openedChest}
        />
        <div className={classes.claimWord}>
          CLAIM
        </div>
      </button>
      <div className={classes.emptyReward}>
        <img
          alt={'chest'}
          className={classes.chest}
          src={chest}
        />
      </div>
      <div className={classes.emptyReward}>
        <img
          alt={'chest'}
          className={classes.chest}
          src={chest}
        />
      </div>
    </div>
  );
}

export default RewardSection;