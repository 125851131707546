import React, {useEffect, useState} from 'react';
import classes from './LoginOrRegister.module.css';
import {entranceTypeConstants, violetColor} from '../../utils/constants';
import arrowLeft from '../../images/arrow-left.png';
import arrowRight from '../../images/arrow-right.png';
import PropTypes from 'prop-types';
import {onlyLettersRegex} from '../../utils/regex';

function LastName({entranceType, step, setStep, lastName, setLastName}) {

  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleBackBtn = () => {
    setStep(step - 1);
  }

  const handleNextBtn = () => {
    setTouched(true)
    if(onlyLettersRegex(lastName)){
      setStep(step + 1);
    }
    else {
      setError(true)
    }
  }

  const handleChange = (e) => {
    setLastName(e.target.value)
  }

  useEffect(() => {
    if(touched && !onlyLettersRegex(lastName)) {
      setError(true)
    } else {
      setError(false)
    }
  }, [lastName])

  return (
    <>
      <div className={classes.loginHeader}>
        {entranceType === entranceTypeConstants.loginAsHero ?  'LOG IN' : 'CREATE NEW HERO'}
      </div>
      <div
        className={classes.subHeader}
        style={{visibility: 'hidden'}}
      >
        <div className={classes.newPlayer}>
          {entranceType === entranceTypeConstants.loginAsHero ?  'NEW PLAYER?' : 'ALREADY A PLAYER?'}
        </div>
        <div
          className={classes.createProfile}
        >
          {entranceType === entranceTypeConstants.loginAsHero ?  'CREATE A HERO' : 'LOG IN TO YOUR PROFILE'}
        </div>
      </div>
      <input
        className={classes.nameField}
        onChange={handleChange}
        placeholder="Surname"
        style={{
          borderColor: `${touched && error ? 'red' : violetColor}`,
          marginTop: 0
        }}
        type="text"
        value={lastName}
      />
      <div
        className={classes.warning}
        style={{visibility: `${entranceType === entranceTypeConstants.loginAsHero && 'hidden'}`}}
      >
        BY SIGNING UP YOU AGREE TO THE
        <span> </span>
        <a
          className={classes.warningLink}
          href={'./terms-of-use'}
          target="_blank"
        >
          TERMS OF USE
        </a>
        <span> </span>
        AND
        <span> </span>
        <a
          className={classes.warningLink}
          href={'./privacy-policy'}
          target="_blank"
        >
          PRIVACY POLICY
        </a>
      </div>
      <div
        className={classes.buttonsWrapper}
      >
        <button
          className={classes.backButton}
          onClick={handleBackBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow left'}
              className={classes.arrowInBtn}
              src={arrowLeft}
            />
            <div>BACK</div>
          </div>
        </button>
        <button
          className={classes.continueButton}
          onClick={handleNextBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow right'}
              className={classes.arrowInBtn}
              src={arrowRight}
            />
            <div>NEXT</div>
          </div>
        </button>
      </div>
    </>
  );
}

LastName.propTypes = {
  entranceType: PropTypes.string,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

export default LastName;