import React from 'react';
import loginClasses from '../../LoginOrRegister/LoginOrRegister.module.css';
import leftArrowInVioletCircle from '../../../images/left-arrow-in-violet-circle';
import rightArrowInVioletCircle from '../../../images/right-arrow-in-violet-circle';
import arrowLeft from '../../../images/arrow-left.png';
import arrowRight from '../../../images/arrow-right.png';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (orientation: portrait) {
    font-size: 2rem;
    width: 100%;
    height: 35%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
  }

  @media (max-height: 667px) {
    height: 100%;
  }
`

const HeroAndArrows = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const Arrow = styled.img`
  height: 60px;
`

const Hero = styled.div`
  height: 250px;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 640px) and (max-width: 900px) and (orientation: landscape) {
    height: 4rem;
  }
  
  @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    height: 11rem;
  }
`

const HeroImg = styled.img`
  height: 100%;
  position: relative;
  z-index: 10;
`

const TurquoiseCircle = styled.div`
  display: ${({display}) => display};
  background: #0DFDDC;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 90%;
  width: 100%;
  border-radius: 50%;
`

const Button = styled.button`
  color: white;
  border-radius: 70px;
  height: 4rem;
  margin: .25rem 0;
  font-family: 'Saira-Bold', serif;
  width: 65%;
  background: var(--pink);
  border: 1px solid var(--pink);
  font-size: 28px;
  cursor: pointer;
  
  ${({second}) => (
    second && css`
        background: var(--violet);
        border: 1px solid var(--violet);
      `
  )};

  @media (orientation: landscape) {
    font-size: .5rem;
    height: 1.25rem;
  }
  
  @media (min-height: 320px) and (max-height: 500px) and (orientation: landscape) {
    font-size: .5rem;
    height: .75rem;
    img {
      height: .65rem;
    }
  }
`

const Title = styled.div`
  font-family: 'Saira-ExtraBold', serif;
  color: #6414cc;
  font-size: 36px;
  z-index: 10;
  width: 100%;
  text-align: center;
  font-weight: bold;

  @media (orientation: landscape) {
    font-size: .75rem;
    margin-top: 1rem;
  }
  
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`

const ImageWithBackground = styled.div`
  position: relative;
`

const HeroesSliderTheme = ({
  handleNextBtn,
  handleBackBtn,
  prevSlide,
  nextSlide,
  allHeroes,
  style,
  pickAvatar,
  pickedHero
}) => {
  return (
    <>
      <Title>CHOOSE YOUR HERO</Title>
      <HeroAndArrows>
        <Arrow
          alt={'left Arrow In Violet Circle'}
          onClick={prevSlide}
          src={leftArrowInVioletCircle}
        />

        <Hero>
          {allHeroes.map((hero, index) => {
            return (
              <ImageWithBackground
                key={index}
                style={style(index)}
              >
                <HeroImg
                  alt="hero image"
                  onClick={() => {
                    pickAvatar(index);
                  }}
                  src={hero.bigHero}
                />

                <TurquoiseCircle
                  display={index === pickedHero ? 'block' : 'none'}
                />

              </ImageWithBackground>
            );
          })}

        </Hero>

        <Arrow
          alt={'right Arrow In Violet Circle'}
          onClick={nextSlide}
          src={rightArrowInVioletCircle}
        />
      </HeroAndArrows>
      <ButtonsWrapper>
        <Button
          onClick={handleBackBtn}
          second
        >
          <div className={loginClasses.backBtnContent}>
            <img
              alt={'arrow left'}
              className={loginClasses.arrowInBtn}
              src={arrowLeft}
            />
            <div>BACK</div>
          </div>
        </Button>
        <Button
          onClick={handleNextBtn}
        >
          <div className={loginClasses.backBtnContent}>
            <img
              alt={'arrow right'}
              className={loginClasses.arrowInBtn}
              src={arrowRight}
            />
            <div>NEXT</div>
          </div>
        </Button>
      </ButtonsWrapper>
    </>
  );
}

HeroesSliderTheme.propTypes = {
  handleNextBtn: PropTypes.func.isRequired,
  handleBackBtn: PropTypes.func.isRequired,
  prevSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
  allHeroes: PropTypes.array,
  style: PropTypes.func.isRequired,
  pickAvatar: PropTypes.func.isRequired,
  pickedHero: PropTypes.string.isRequired,
}

export default HeroesSliderTheme;
