import React from 'react';
import classes from './Frame.module.css';
import JumpHeroezLogo from '../../images/JUMPILOT_LOGO.png';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--violet);
  padding: .25rem;
  overflow: auto;

  @media all and (min-width: 1025px) and (orientation: landscape) {
    padding: 2rem 0;
    max-width: 1024px;
    max-height: 768px;
  }
`

const LogoWrapper = styled.div`
  width: 100%;
  height: 18%;
  margin-top: 4%;
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
  z-index: 5;
  display: flex;
  justify-content: center;

  @media (min-width: 270px) and (max-width: 319px) {
    height: 50px;
    top: 4%;
  }
  
  @media (min-width: 320px) and (max-width: 500px) {
    height: 50px;
    top: 4%;
  }
  
  @media (max-height: 640px) and (orientation: landscape) {
    height: 40px;
    top: 4%;
  }
  
  @media (max-height: 568px) {
    height: 50px;
    top: 4%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100px;
  }
  
  @media (min-width: 812px) and (max-width: 1024px) and (orientation: landscape){
    height: 60px;
  }
  
  @media (max-height: 1366px) and (min-height: 1025px) {
    height: 150px;
  }

`

const Logo = styled.img`
  height: 100%;
`

const HeroContent = styled.div`
  background-color: white;
  width: 95%;
  height: 80%;
  border-radius: 50px;
  box-shadow: 0 10px 35px -7px #000000;
  padding: 1.5rem .25rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (orientation: landscape) {
    padding: 0;
  }
  
`

const Frame = ({children}) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo
          alt={'JumpHeroez Logo'}
          className={classes.jumpHeroezLogo}
          src={JumpHeroezLogo}
        />
      </LogoWrapper>
      <HeroContent>
        {children}
      </HeroContent>
    </Wrapper>
  );
}

export default Frame;