import React, {useEffect} from 'react';
import classes from './App.module.css';
import LoginOrRegister from './components/LoginOrRegister/LoginOrRegister';
import { Route, Switch } from 'react-router-dom';
import TermsOfUse from './components/TermsOfUseAndPrivacyPolicy/TermsOfUse';
import PrivacyPolicy from './components/TermsOfUseAndPrivacyPolicy/PrivacyPolicy';
import PageNotFound from './components/PageNotFound';
import UserPanel from './components/UserPanel/UserPanel';
import QRPath from './components/QRpath/QRPath';
import {useDispatch} from 'react-redux';
import Cookie from 'js-cookie';
import {loginUserSuccess} from './store/actions/auth.actions';
import PhoneNumberPath from './components/PhoneNumberPath/PhoneNumberPath';
import withLayout from './HOC/withLayout';
import Frame from './layouts/Frame/Frame';
import EndOfJumpHeroez from './components/EndOfJumpHeroez/EndOfJumpHeroez';
import RewardCode from './components/PhoneNumberPath/RewardCode';
import Rewards from './components/UserPanel/Rewards/Rewards';

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const token = Cookie.get('AUTH-TOKEN');
    if (token) {
      dispatch(loginUserSuccess());
    }
  }, [dispatch]);

  return (
    <div className={classes.wrapper}>
      <Switch>
        <Route
          component={withLayout(Frame, LoginOrRegister)}
          exact
          path="/"
        />
        <Route
          exact
          path="/home"
          render={(routeProps) => <UserPanel {...routeProps} />}
        />
        <Route
          path="/qr"
          render={(routeProps) => <QRPath {...routeProps} />}
        />
        <Route
          component={withLayout(Frame, PhoneNumberPath)}
          exact
          path="/phone-number"
        />
        <Route
          exact
          path="/reward-code"
          render={(routeProps) => <RewardCode {...routeProps} />}
        />
        <Route
          exact
          path="/rewards"
          render={(routeProps) => <Rewards {...routeProps} />}
        />
        <Route
          exact
          path="/end-of-jump-heroez"
          render={(routeProps) => <EndOfJumpHeroez {...routeProps} />}
        />
        <Route
          component={withLayout(Frame, TermsOfUse)}
          exact
          path="/terms-of-use"
        />
        <Route
          component={withLayout(Frame, PrivacyPolicy)}
          exact
          path="/privacy-policy"
        />
        <Route render={() => <PageNotFound/>}/>
      </Switch>
    </div>
  );
}

export default App;
