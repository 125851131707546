import React, {useEffect, useState} from 'react';
import UserPanelMobile from './UserPanelMobile/UserPaneMobile'
import UserPanelTablet from './UserPanelTablet/UserPanelTablet'
import {useDispatch, useSelector} from 'react-redux';
import {getDashBoardData} from '../../store/actions/dashboard.actions';
import Cookie from 'js-cookie'
import {useHistory} from 'react-router-dom'
import DashboardError from './UserPanelError/DashboardError';

function UserPanel() {

  const history = useHistory();

  const dispatch = useDispatch();

  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const dashboardData = useSelector(state => state.dashboardReducer.dashboardData);

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/')
    }
  }, [isLoggedIn])

  useEffect(() => {
    if(!dashboardData){
      const token = Cookie.get('AUTH-TOKEN');
      dispatch(getDashBoardData(token))
    }
  }, [dispatch, dashboardData])

  const error = useSelector(state => state.dashboardReducer.error)

  return (
    <>
      { error ? <DashboardError/>
        : <>
          {
            dashboardData && (
              innerWidth < innerHeight
                ? <UserPanelMobile/>
                : <UserPanelTablet/>
            )
          }
        </>
      }
    </>
  )
}

export default UserPanel;