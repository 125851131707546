import React from 'react';
import JumpHeroezLogo from '../../../images/JUMPILOT_LOGO.png'
import classes from './Loading.module.css'

function Loading() {
  return (
    <div className={classes.mainWrapper}>
      <img
        alt={'jumpHeroez Logo'}
        className={classes.JumpHeroezLogo}
        src={JumpHeroezLogo}
      />
      <div className={classes.progressBarWrapper}>
        <div className={classes.progressBar}/>
        <div className={classes.progressBarText}>
          <div>
            LOADING
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;