export const CHECK_PHONE_NUMBER = 'CHECK_PHONE_NUMBER'
export const CHECK_PHONE_NUMBER_SUCCESS = 'CHECK_PHONE_NUMBER_SUCCESS'
export const CHECK_PHONE_NUMBER_FAILURE = 'CHECK_PHONE_NUMBER_FAILURE'
export const PHONE_NUMBER_REQUEST = 'PHONE_NUMBER_REQUEST'
export const PHONE_NUMBER_SENT_SUCCESS = 'PHONE_NUMBER_SENT_SUCCESS'
export const PHONE_NUMBER_SENT_FAILURE = 'PHONE_NUMBER_SENT_FAILURE'
export const RESET_PHONE_NUMBER = 'RESET_PHONE_NUMBER'
export const CONFIRMATION_CODE_REQUEST = 'CONFIRMATION_CODE_REQUEST'
export const CONFIRMATION_CODE_SENT_SUCCESS = 'CONFIRMATION_CODE_SENT_SUCCESS'
export const CONFIRMATION_CODE_SENT_FAILURE = 'CONFIRMATION_CODE_SENT_FAILURE'

export const checkPhoneNumber = (body) => {
  return {
    type: CHECK_PHONE_NUMBER,
    payload: body
  }
}

export const checkPhoneNumberSuccess = (body) => {
  return {
    type: CHECK_PHONE_NUMBER_SUCCESS,
    payload: body
  }
}

export const checkPhoneNumberFailure = (body) => {
  return {
    type: CHECK_PHONE_NUMBER_FAILURE,
    payload: body
  }
}

export const assignPhoneNumberRequest = (body) => {
  return {
    type: PHONE_NUMBER_REQUEST,
    payload: body
  }
}

export const assignPhoneNumberSuccess = (body) => {
  return {
    type: PHONE_NUMBER_SENT_SUCCESS,
    payload: body
  }
}

export const assignPhoneNumberFailure = (body) => {
  return {
    type: PHONE_NUMBER_SENT_FAILURE,
    payload: body
  }
}

export const resetPhoneNumber = () => {
  return {
    type: RESET_PHONE_NUMBER
  }
}

export const confirmationCodeRequest = (body) => {
  return {
    type: CONFIRMATION_CODE_REQUEST,
    payload: body
  }
}

export const confirmationCodeSuccess = (body) => {
  return {
    type: CONFIRMATION_CODE_SENT_SUCCESS,
    payload: body
  }
}

export const confirmationCodeFailure = (body) => {
  return {
    type: CONFIRMATION_CODE_SENT_FAILURE,
    payload: body
  }
}


