import {
  CHECK_PHONE_NUMBER_FAILURE,
  CHECK_PHONE_NUMBER_SUCCESS, CONFIRMATION_CODE_SENT_FAILURE, CONFIRMATION_CODE_SENT_SUCCESS,
  PHONE_NUMBER_SENT_FAILURE,
  PHONE_NUMBER_SENT_SUCCESS, RESET_PHONE_NUMBER
} from '../actions/phoneNumber.actions';

const initialState = {
  phoneNumberAlreadyExists: '',
  phoneNumberSent: '',
  confirmationCodeSuccess: '',
  phoneNumberFailureMessage: ''
}

export const phoneNumberReducer = (state = initialState, action) => {
  switch(action.type){
    case CHECK_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        phoneNumberAlreadyExists: true
      }
    case CHECK_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        phoneNumberAlreadyExists: false
      }
    case PHONE_NUMBER_SENT_SUCCESS:
      return {
        ...state,
        phoneNumberSent: true
      }
    case PHONE_NUMBER_SENT_FAILURE:
      return {
        ...state,
        phoneNumberSent: false,
        phoneNumberFailureMessage: action.payload
      }
    case RESET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumberSent: '',
      }
    case CONFIRMATION_CODE_SENT_SUCCESS:
      return {
        ...state,
        confirmationCodeSuccess: true
      }
    case CONFIRMATION_CODE_SENT_FAILURE:
      return {
        ...state,
        confirmationCodeSuccess: false
      }
    default:
      return state
  }
}