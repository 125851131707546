import React, {useEffect, useState} from 'react';
import classes from './PhoneNumberPath.module.css';
import arrowLeft from '../../images/arrow-left.png';
import arrowRight from '../../images/arrow-right.png';
import PropTypes from 'prop-types';
import {assignPhoneNumberRequest} from '../../store/actions/phoneNumber.actions';
import {useDispatch, useSelector} from 'react-redux';
import Cookie from 'js-cookie';
import {useHistory} from 'react-router-dom';
import {isValidPhoneNumber} from 'libphonenumber-js'
import {useForm} from "react-hook-form";
import styled from "styled-components";
import cx from 'classnames';

const Error = styled.div`
  color: red;
  font-size: .5rem;
  padding: 0;
  font-weight: 700;
  margin-left: .25rem;
`

const InputWrapper = styled.div`
  margin: 5% 0;
`

function UserPhoneNumber({setStep, step, setIsFetching}) {

    const history = useHistory();

    const [phoneNumberFailure, setPhoneNumberFailure] = useState(false);

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur"
    });

    const dispatch = useDispatch();

    const phoneNumberSent = useSelector(state => state.phoneNumberReducer.phoneNumberSent);
    const phoneNumberFailureMessage = useSelector(state => state.phoneNumberReducer.phoneNumberFailureMessage)

    const handleBackBtn = () => {
        history.push('/home')
    }

    useEffect(() => {
        if (phoneNumberSent === true) {
            setIsFetching(false)
            setStep(step + 1);
        } else if (phoneNumberSent === false) {
            setPhoneNumberFailure(true)
        }
    }, [phoneNumberSent])

    const handleNextBtn = ({phoneNumber}) => {
        const token = Cookie.get('AUTH-TOKEN');
        dispatch(assignPhoneNumberRequest({phoneNumber, token}));
        setIsFetching(true)
    }

    return (
        <>
            <div className={classes.rewardHeader}>
                COLLECT REWARD
            </div>
            <div className={classes.rewardSubheader}>
                YOU WILL RECEIVE A REWARD, PLEASE ADD YOUR MOBILE NUMBER.
            </div>

            <form onSubmit={handleSubmit(handleNextBtn)}>
                <InputWrapper>
                    <input
                        className={cx(classes.phoneField, {[classes.phoneFieldError]: errors.phoneNumber})}
                        placeholder="+48 000 000 000"
                        type="text"
                        autoComplete='off'
                        {...register('phoneNumber', {
                            validate: (data) => isValidPhoneNumber(data)
                        })}
                    />
                    {errors.phoneNumber &&
                    <Error><i className="fas fa-exclamation-triangle"/> Invalid phone number</Error>}
                </InputWrapper>

                <div
                    className={classes.warning}
                    style={{visibility: `${phoneNumberSent === false ? 'visible' : 'hidden'}`}}
                >
                    {phoneNumberFailure && phoneNumberFailureMessage}
                </div>
                <div
                    className={classes.buttonsWrapper}
                >
                    <button
                        className={classes.backButton}
                        onClick={handleBackBtn}
                        type='button'
                    >
                        <div className={classes.backBtnContent}>
                            <img
                                alt={'arrow in button'}
                                className={classes.arrowInBtn}
                                src={arrowLeft}
                            />
                            <div>BACK</div>
                        </div>
                    </button>
                    <button
                        className={classes.continueButton}
                        type='submit'
                    >
                        <div className={classes.backBtnContent}>
                            <img
                                alt={'arrow in button'}
                                className={classes.arrowInBtn}
                                src={arrowRight}
                            />
                            <div>Next</div>
                        </div>
                    </button>
                </div>
            </form>
        </>
    );
}

UserPhoneNumber.propTypes = {
    setIsFetching: PropTypes.func,
    setStep: PropTypes.func,
    step: PropTypes.number
}

export default UserPhoneNumber;