import {
  ASSIGN_SESSION_SUCCESS,
  REDIRECT_TO_LOGIN_PAGE,
  RESET_QR,
  SET_QR_DATA,
  SET_RETURN_USER
} from '../actions/qr.actions';
import {LOGOUT} from '../actions/auth.actions';

const initialState = {
  qrData: '',
  redirectToLoginPage: false,
  sessionAssigned: false
}

export const qrReducer = (state = initialState, action) => {
  switch(action.type){
    case SET_QR_DATA:
      return{
        ...state,
        qrData: action.payload
      }
    case REDIRECT_TO_LOGIN_PAGE:
      return {
        ...state,
        redirectToLoginPage: true
      }
    case ASSIGN_SESSION_SUCCESS:
      return {
        ...state,
        sessionAssigned: true
      }
    case RESET_QR:
      return {
        ...state,
        qrData: '',
        sessionAssigned: false,
        redirectToLoginPage: false,
      }
    default:
      return state
  }
}