export const onlyLettersRegex = (string) => {
  return /^[a-zA-Z ]+$/.test(string);
}

export const phoneNumberRegex = (string) => {
  return /^\+[0-9]{11}$/.test(string);
}

export const confirmationCodeRegex = (string) => {
  return /^[0-9]{4}$/.test(string);
}
