import {call, put, takeEvery} from '@redux-saga/core/effects';
import {dashboardApi} from '../../api/api';
import {
  GET_DASHBOARD_DATA,
  GET_MAIN_REWARD,
  setDashBoardData,
  setMainReward,
  showDashboardError
} from '../actions/dashboard.actions';

export function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, function* (action) {
    try {
      const response = yield call(dashboardApi.getDashboardData, action.payload)
      yield put(setDashBoardData(response.data))
    }
    catch (error) {
      console.log(error)
      yield put(showDashboardError())
    }
  })
  yield takeEvery(GET_MAIN_REWARD, function* (action) {
    try {
      const response = yield call(dashboardApi.getMainReward, action.payload)
      yield put(setMainReward(response.data))
    }
    catch (error) {
      console.log(error)
      yield put(showDashboardError())
    }
  })

}