export const violetColor = '#6F03D0';
export const lightTurquoiseColor = '#D7FFF7'
export const brightTurquoiseColor = '#0DFDDC';
export const yellow = '#FFEE00'
export const palePink = '#E980B8'
export const brightPink = '#FC119A'

export const entranceTypeConstants = {
  loginAsHero: 'Registration as hero',
  createAHero:'Create a hero'
}

export const errorConstants = {
  alreadyExists: 'ALREADY_EXIST'
}

export const FREE_30_MIN = 'Free 30 min'