import React, {useCallback, useEffect, useState} from 'react';
import HeroesSliderMobile from './HeroesSliderMobile/HeroesSliderMobile';
import HeroesSliderTablet from './HeroesSliderTablet/HeroesSliderTablet';
import PropTypes from 'prop-types';
import HeroesSliderMiddle from './HeroesSliderMiddle/HeroesSliderMiddle';

function HeroesSlider({ step, setStep, setAvatar }) {

  const [size, setSize] = useState('')

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 550) {
        setSize('small')
      } else if (window.innerWidth > 550 && window.innerWidth <= 1024) {
        setSize('medium')
      } else {
        setSize('large')
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const renderByInnerWidth = useCallback(() => {
    if (size === 'small') {
      return <HeroesSliderMobile
        setAvatar={setAvatar}
        setStep={setStep}
        step={step}
             />
    }
    if (size === 'medium') {
      return <HeroesSliderMiddle
        setAvatar={setAvatar}
        setStep={setStep}
        step={step}
             />
    } else {
      return <HeroesSliderTablet
        setAvatar={setAvatar}
        setStep={setStep}
        step={step}
             />
    }
  }, [size])

  return renderByInnerWidth()
}

HeroesSlider.propTypes = {
  sendRequest: PropTypes.func,
  setAvatar: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

export default HeroesSlider;
