export const GET_DASHBOARD_DATA = 'GET DASHBOARD DATA'
export const SET_DASHBOARD_DATA = 'SET DASHBOARD DATA'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const SHOW_DASHBOARD_ERROR = 'SHOW_DASHBOARD_ERROR'
export const HIDE_DASHBOARD_ERROR = 'HIDE_DASHBOARD_ERROR'
export const GET_MAIN_REWARD = 'GET_MAIN_REWARD'
export const SET_MAIN_REWARD = 'SET_MAIN_REWARD'

export const getDashBoardData = (body) => {
  return {
    type: GET_DASHBOARD_DATA,
    payload: body
  }
}

export const setDashBoardData = (body) => {
  return {
    type: SET_DASHBOARD_DATA,
    payload: body
  }
}

export const cleaUserData = () => {
  return {
    type: CLEAR_USER_DATA
  }
}

export const showDashboardError = () => {
  return {
    type: SHOW_DASHBOARD_ERROR
  }
}

export const hideDashboardDataError = () => {
  return {
    type: HIDE_DASHBOARD_ERROR
  }
}

export const getMainReward = (body) => {
  return {
    type: GET_MAIN_REWARD,
    payload: body
  }
}

export const setMainReward = (body) => {
  return {
    type: SET_MAIN_REWARD,
    payload: body
  }
}
