import React, {useEffect, useState} from 'react';
import classes from './LoginOrRegister.module.css';
import {entranceTypeConstants, violetColor} from '../../utils/constants';
import arrowLeft from '../../images/arrow-left.png';
import arrowRight from '../../images/arrow-right.png';
import PropTypes from 'prop-types'
import {onlyLettersRegex} from '../../utils/regex';

function FirstName({entranceType, setEntranceType, step, setStep, firstName, setFirstName}) {

  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleBackBtn = () => {
    setStep(step - 1)
  }

  const handleNextBtn = () => {
    setTouched(true)
    if(onlyLettersRegex(firstName)){
      setStep(step + 1);
    }
    else {
      setError(true)
    }
  }

  const handleChange = (e) => {
    setFirstName(e.target.value)
  }

  const changeEntranceType = () => {
    if(entranceType === entranceTypeConstants.loginAsHero) {
      setEntranceType(entranceTypeConstants.createAHero)
    } else if (entranceType === entranceTypeConstants.createAHero){
      setEntranceType(entranceTypeConstants.loginAsHero)
    }
  }

  useEffect(() => {
    if(touched && !onlyLettersRegex(firstName)) {
      setError(true)
    } else {
      setError(false)
    }
  }, [firstName])

  return (
    <>
      <div>
        <div className={classes.loginHeader}>
          {entranceType === entranceTypeConstants.loginAsHero ?  'LOG IN' : 'CREATE NEW HERO'}
        </div>
        <div className={classes.subHeader}>
          <div className={classes.newPlayer}>
            {entranceType === entranceTypeConstants.loginAsHero ?  'NEW PLAYER?' : 'ALREADY A PLAYER?'}
          </div>
          <div
            className={classes.createProfile}
            onClick={changeEntranceType}
          >
            {entranceType === entranceTypeConstants.loginAsHero ?  'CREATE A HERO' : 'LOG IN TO YOUR PROFILE'}
          </div>
        </div>
      </div>
      <input
        className={classes.nameField}
        onChange={handleChange}
        placeholder="Name"
        style={{borderColor: `${touched && error ? 'red' : violetColor}`}}
        type="text"
        value={firstName}
      />
      <div
        className={classes.warning}
        style={{visibility: `${entranceType === entranceTypeConstants.loginAsHero ? 'hidden' : 'visible'}`}}
      >
        BY SIGNING UP YOU AGREE TO THE
        <span> </span>
        <a
          className={classes.warningLink}
          href={'./terms-of-use'}
          target="_blank"
        >
          TERMS OF USE
        </a>
        <span> </span>
        AND
        <span> </span>
        <a
          className={classes.warningLink}
          href={'./privacy-policy'}
          target="_blank"
        >
          PRIVACY POLICY
        </a>
      </div>
      <div
        className={classes.buttonsWrapper}
      >
        <button
          className={classes.backButton}
          onClick={handleBackBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow in button'}
              className={classes.arrowInBtn}
              src={arrowLeft}
            />
            <div>BACK</div>
          </div>
        </button>
        <button
          className={classes.continueButton}
          onClick={handleNextBtn}
        >
          <div className={classes.backBtnContent}>
            <img
              alt={'arrow in button'}
              className={classes.arrowInBtn}
              src={arrowRight}
            />
            <div>NEXT</div>
          </div>
        </button>
      </div>
    </>
  );
}

FirstName.propTypes = {
  entranceType: PropTypes.string,
  firstName: PropTypes.string,
  setEntranceType: PropTypes.func,
  setFirstName: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

export default FirstName;