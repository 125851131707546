import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import HeroesSliderTheme from '../../../themes/HeroesSliderTheme/HeroesSliderTheme';

const HeroesSliderMiddle = ({step, setStep, setAvatar}) => {

  const allHeroes = useSelector(state => state.heroesReducer.heroes)

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(1);
  const [pickedHero, setPickedHero] = useState('');

  const nextSlide = () => {
    if (max === 19) {
      setMin(0);
      setMax(1);
    } else {
      setMin(min + 2);
      setMax(max + 2);
    }
  };

  const prevSlide = () => {
    if (min === 0) {
      setMin(18);
      setMax(19);
    } else {
      setMin(min - 2);
      setMax(max - 2);
    }
  };

  const handleBackBtn = () => {
    setStep(step - 1);
  };

  const handleNextBtn = () => {
    pickedHero !== '' &&
        setAvatar(allHeroes[pickedHero].name);
  };

  const pickAvatar = (index) => {
    setPickedHero(index);
  }

  const style = (index) => ({
    width: `${index >= min && index <= max ? '30%' : 0}`,
    marginRight: `${index >= min && index <= max ? '13px' : 0}`,
    marginLeft: `${index >= min && index <= max ? '13px' : 0}`,
    height: `${index >= min && index <= max ? '90%' : 0}`,
    display: `${index >= min && index <= max ? 'block' : 'none'}`,
  })
  return (
    <HeroesSliderTheme
      allHeroes={allHeroes}
      handleBackBtn={handleBackBtn}
      handleNextBtn={handleNextBtn}
      nextSlide={nextSlide}
      pickAvatar={pickAvatar}
      pickedHero={pickedHero}
      prevSlide={prevSlide}
      style={style}
    />
  );
};

export default HeroesSliderMiddle;