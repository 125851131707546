import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getMainReward} from '../../../store/actions/dashboard.actions';
import Cookie from 'js-cookie';
import {FREE_30_MIN} from '../../../utils/constants';
import classes from './Rewards.module.css'
import {useHistory} from 'react-router-dom';
import RewardCode from '../../PhoneNumberPath/RewardCode';
import img from '../../../images/error-kenji.png';
import jumpLogo from '../../../images/JUMPILOT_LOGO.png';
import arrow from '../../../images/arrow-left.png';

function Rewards() {

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookie.get('AUTH-TOKEN');
    token
      ? dispatch(getMainReward(token))
      : history.push('/')
  }, []);

  const mainReward = useSelector(state => state.dashboardReducer.mainReward);

  const handleBackBtn = () => {
    history.push('/home')
  }

  return (
    <>
      { mainReward?.name === FREE_30_MIN
        ? <RewardCode />
        : <div className={classes.mainWrapper}>
          <div className={classes.logoWrapper}>
            <img
              alt={'jump heroez logo'}
              className={classes.logoImg}
              src={jumpLogo}
            />
          </div>
          <div className={classes.mainSection}>
            <div className={classes.leftColumn}>
              <img
                alt={'Jump Heroez'}
                className={classes.img}
                src={img}
              />
            </div>
            <div className={classes.rightColumn}>
              <div className={classes.header}>
                OOPS! NO REWARDS YET
              </div>
              <div className={classes.subHeader}>
                Keep jumping and scoring points
              </div>
              <button
                className={classes.button}
                onClick={handleBackBtn}
              >
                <img
                  alt={'arrow'}
                  className={classes.arrow}
                  src={arrow}
                />
                <div>
                  BACK
                </div>
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Rewards;