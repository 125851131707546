import React from 'react';
import classes from './UserPanelTablet.module.css'
import Header from './Header/Header';
import UserInfoHeader from './UserInfoHeader/UserInfoHeader';
import MainSection from './MainSection/MainSection';

function UserPanelTablet() {

  return (
    <div
      className={classes.mainWrapper}
    >
      <Header/>
      <div className={classes.whiteWrapper}>
        <div className={classes.contentWrapper}>
          <UserInfoHeader/>
          <MainSection/>
        </div>
      </div>
    </div>
  );
}

export default UserPanelTablet;