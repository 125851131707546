import classes from './RewardCode.module.css';
import freeMinutes from '../../images/30minutes.png'
import arrowDown from '../../images/arrow-down.png'
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Cookie from 'js-cookie';
import {getMainReward} from '../../store/actions/dashboard.actions';
import Frame from '../../layouts/Frame/Frame';

function RewardCode() {

  const history = useHistory();

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/')
    }
  }, [isLoggedIn])

  const goHome = () => {
    history.push('/home')
  }

  useEffect(() => {
    const token = Cookie.get('AUTH-TOKEN');
    dispatch(getMainReward(token))
  }, []);

  const mainReward = useSelector(state => state.dashboardReducer.mainReward);

  const rewardPin = mainReward && `${mainReward.pin.toString().substring(0,3)} – ${mainReward.pin.toString().substring(3,6)}`

  return (
    <Frame>
      <div className={classes.textWrapper}>
        <div className={classes.firstBlock}>
          <div className={classes.header}>SHOW CODE AT THE FRONT DESK TO CLAIM REWARD</div>
        </div>
        <div className={classes.thirdBlock} >
          <div className={classes.codeWrapper}>
            {rewardPin}
          </div>
        </div>
        <div className={classes.secondBlock}>
          <img
            alt={'30 minutes free'}
            className={classes.freeMinutes}
            src={freeMinutes}
          />
        </div>
        <div className={classes.fourthBlock}>
          <button
            className={classes.violetButton}
            onClick={goHome}
          >
            <img
              alt={'arrow down'}
              className={classes.arrowDown}
              src={arrowDown}
            />
          BACK
          </button>
        </div>
      </div>
    </Frame>

  );
}

export default RewardCode;