import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://jumpheroez.com:8443',
})

export const authApi = {
  login(body){
    return instance.post('/app/user/login', body)
  },
  register(body){
    return instance.post('/app/user/register', body)
  }
}

export const dashboardApi = {
  getDashboardData(token) {
    return instance.get('/app/session/get-dashboard-data', {
      headers: {
        'Authorization': token
      }
    })
  },
  getMainReward(token){
    return instance.get('/app/prize/main', {
      headers: {
        'Authorization': token
      }
    })
  }
}

export const qrApi = {
  loginQR(body){
    return instance.post('/app/user/qr-login', body)
  },
  assignSession(body){
    return instance.post(`/app/session/assign-session/${body.qrData}`, null, {
      headers: {
        'Authorization': body.token
      }
    })
  }
}

export const phoneNumberApi = {
  checkPhoneNumber(token){
    return instance.get('/app/phone/get-phone', {
      headers: {
        'Authorization': token
      }
    })
  },
  assignPhoneNumber(body){
    return instance.post(`/app/phone/assign-number/${body.phoneNumber}`, null, {
      headers: {
        'Authorization': body.token
      }
    })
  },
  sendConfirmationCode(body){
    return instance.post(`/app/phone/activate-phone/${body.confirmationCode}`, null, {
      headers: {
        'Authorization': body.token
      }
    })
  }
}
