import React from 'react';
import classes from './UserPanelMobile.module.css'
import HeaderMobile from './HeaderMobile/HeaderMobile';
import UserInfoHeader from './UserInfoHeaderMobile/UserInfoHeader';
import MainSectionMobile from './MainSectionMobile/MainSectionMobile';

function UserPaneMobile({}) {
  return (
    <div className={classes.mainWrapper}>
      <HeaderMobile/>
      <div className={classes.whiteWrapper}>
        <div className={classes.contentWrapper}>
          <UserInfoHeader/>
          <MainSectionMobile/>
        </div>
      </div>
    </div>
  );
}

export default UserPaneMobile;