import React from 'react';
import classes from './EntranceType.module.css';
import LoginAsHero from '../../../images/login-as-hero.png';
import createAHero from '../../../images/create-a-hero.png';
import {entranceTypeConstants} from '../../../utils/constants';
import PropTypes from 'prop-types';

function EntranceType({step, setEntranceType, setStep}) {

  const setNextStep = (entranceType) => {
    setEntranceType(entranceType)
    setStep(step + 1)
  }

  return (
    <div className={classes.imgWrapper}>
      <img
        alt={'login as hero'}
        className={classes.heroImg}
        onClick={() => setNextStep(entranceTypeConstants.loginAsHero)}
        src={LoginAsHero}
      />
      <img
        alt={'create a hero'}
        className={classes.heroImg}
        onClick={() => setNextStep(entranceTypeConstants.createAHero)}
        src={createAHero}
      />
    </div>
  );
}

EntranceType.propTypes = {
  setEntranceType: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

export default EntranceType;