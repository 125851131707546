export const LOGIN_USER_REQUEST = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const REGISTER_USER = 'REGISTER_USER'
export const SHOW_ERROR = 'SHOW_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'
export const LOGOUT = 'LOGOUT'

export const loginUserRequest = (body) => {
  return {
    type: LOGIN_USER_REQUEST,
    payload: body
  }
}

export const loginUserSuccess = (body) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: body
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  }
}

export const registerUserRequest = (body) => {
  return {
    type: REGISTER_USER,
    payload: body
  }
}

export const showError = (body) => {
  return {
    type: SHOW_ERROR,
    payload: body
  }
}

export const hideError = () => {
  return {
    type: HIDE_ERROR
  }
}
