import React from 'react';
import classes from './RewardSection.module.css';
import openedChest from '../../../../../images/opened-chest.png';
import chest from '../../../../../images/chest.png';
import {useHistory} from 'react-router-dom';

function RewardSection() {

  const history = useHistory()

  const goToEndOfJumpHeroez = () => {
    history.push('/end-of-jump-heroez')
  }

  return (
    <div className={classes.rewardsSectionWrapper}>
      <button
        className={classes.rewardWrapper}
        onClick={goToEndOfJumpHeroez}
      >
        <img
          alt={'opened chest'}
          className={classes.openedChest}
          src={openedChest}
        />
        <div className={classes.claimWord}>CLAIM</div>
      </button>
      <button className={classes.emptyReward}>
        <img
          alt={'chest'}
          className={classes.chest}
          src={chest}
        />
      </button>
    </div>
  );
}

export default RewardSection;