import {call, put, takeEvery} from '@redux-saga/core/effects';
import {
  LOGIN_USER_REQUEST,
  loginUserRequest,
  loginUserSuccess,
  REGISTER_USER,
  showError
} from '../actions/auth.actions';
import {authApi} from '../../api/api';
import Cookie from 'js-cookie'

export function* authSaga() {
  yield takeEvery(REGISTER_USER, function* (action) {
    try {
      yield call(authApi.register, action.payload)
      yield put(loginUserRequest(action.payload))
    } catch (error) {
      yield put(showError(error.response.data))
    }
  })
  yield takeEvery(LOGIN_USER_REQUEST, function* (action) {
    try {
      const response = yield call(authApi.login, action.payload)
      console.log('response', response)
      const token = `Bearer ${response.data?.split(' ')[1]}`
      Cookie.set('AUTH-TOKEN', token)
      yield put(loginUserSuccess())
    } catch (error) {
      console.log('in catch', error.response)
      yield put(showError(error.response))
    }
  })
}