import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import HeroesSliderTheme from '../../../themes/HeroesSliderTheme/HeroesSliderTheme';

function HeroesSliderTablet({step, setStep, setAvatar}) {

  const allHeroes = useSelector(state => state.heroesReducer.heroes)

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(3);
  const [pickedHero, setPickedHero] = useState('');

  const nextSlide = () => {
    if (max === 19) {
      setMin(0);
      setMax(3);
    } else {
      setMin(min + 4);
      setMax(max + 4);
    }
  };

  const prevSlide = () => {
    if (min === 0) {
      setMin(16);
      setMax(19);
    } else {
      setMin(min - 4);
      setMax(max - 4);
    }
  };

  const handleBackBtn = () => {
    setStep(step - 1);
  };

  const handleNextBtn = () => {
    pickedHero !== '' &&
        setAvatar(allHeroes[pickedHero].name);
  };

  const pickAvatar = (index) => {
    setPickedHero(index);
  }

  const style = (index) => ({
    width: `${index >= min && index <= max ? '24%' : 0}`,
    marginRight: `${index >= min && index <= max ? '13px' : 0}`,
    marginLeft: `${index >= min && index <= max ? '13px' : 0}`,
    height: `${index >= min && index <= max ? '90%' : 0}`,
    display: `${index >= min && index <= max ? 'block' : 'none'}`,
  })

  return (
    <HeroesSliderTheme
      allHeroes={allHeroes}
      handleBackBtn={handleBackBtn}
      handleNextBtn={handleNextBtn}
      nextSlide={nextSlide}
      pickAvatar={pickAvatar}
      pickedHero={pickedHero}
      prevSlide={prevSlide}
      style={style}
    />
  );
}

HeroesSliderTablet.propTypes = {
  setAvatar: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

export default HeroesSliderTablet;
