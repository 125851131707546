import React from 'react';
import classes from './MainSection.module.css';
import {useSelector} from 'react-redux';
import LevelSection from './LevelSection/LevelSection';
import RewardSection from './RewardSection/RewardSection';
import KingOfTheDay from './KingOfTheDay/KingOfTheDay';

function MainSection() {

  const dashboardData = useSelector(state => state.dashboardReducer.dashboardData)

  const heroIcons = useSelector(state => state.heroesReducer.heroes)

  const heroIcon = dashboardData && heroIcons.find(icon => icon.name === dashboardData.user.avatar).bigHero

  return (
    <div className={classes.mainSectionWrapper}>
      <img
        alt={`${dashboardData.user?.avatar}`}
        className={classes.bigHeroImg}
        src={heroIcon}
      />
      <div className={classes.resultsWrapper}>
        <LevelSection/>
        <RewardSection/>
        <KingOfTheDay/>
      </div>
    </div>
  );
}

export default MainSection;
