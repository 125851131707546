import React, {useEffect, useState} from 'react';
import Loading from '../common/Loading/Loading';
import ProgressBar from '../common/ProgressBar/ProgressBar';
import UserPhoneNumber from './UserPhoneNumber';
import ConfirmationCode from './ConfirmationCode';
import Reward30Min from './Reward30Min/Reward30Min';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

function PhoneNumberPath() {

  const history = useHistory();

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn)

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     history.push('/')
  //   }
  // }, [isLoggedIn])

  const [step, setStep] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  return (
    <>
      {step === 1 && (
        <UserPhoneNumber
          setIsFetching={setIsFetching}
          setStep={setStep}
          step={step}
        />)}
      {step === 2 && (
        <ConfirmationCode
          setStep={setStep}
          step={step}
        />
      )}
      {step === 3 && (
        <Reward30Min
          setStep={setStep}
          step={step}
        />
      )}
      {isFetching && (
        <Loading/>
      )}
      <ProgressBar
        step={step}
      />
    </>
  );
}

export default PhoneNumberPath;