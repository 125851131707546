import {call, put, takeEvery} from '@redux-saga/core/effects';
import {
  CHECK_PHONE_NUMBER, checkPhoneNumberFailure, checkPhoneNumberSuccess,
  PHONE_NUMBER_REQUEST,
  assignPhoneNumberFailure,
  assignPhoneNumberSuccess, CONFIRMATION_CODE_REQUEST, confirmationCodeFailure, confirmationCodeSuccess
} from '../actions/phoneNumber.actions';
import {phoneNumberApi} from '../../api/api';

export function* phoneNumberSaga() {
  yield takeEvery(CHECK_PHONE_NUMBER, function* (action) {
    try {
      const response = yield call(phoneNumberApi.checkPhoneNumber, action.payload)
      yield put(checkPhoneNumberSuccess(response.data))
    }
    catch (error) {
      yield put(checkPhoneNumberFailure())
    }
  })
  yield takeEvery(PHONE_NUMBER_REQUEST, function* (action) {
    try {
      const response = yield call(phoneNumberApi.assignPhoneNumber, action.payload)
      yield put(assignPhoneNumberSuccess(response.data))
    }
    catch (error) {
      yield put(assignPhoneNumberFailure(error.response))
    }
  })
  yield takeEvery(CONFIRMATION_CODE_REQUEST, function* (action) {
    try {
      const response = yield call(phoneNumberApi.sendConfirmationCode, action.payload)
      yield put(confirmationCodeSuccess(response.data))
    }
    catch (error) {
      yield put(confirmationCodeFailure())
    }
  })

}