import React from 'react';
import classes from './styles.module.css';

function TermsOfUse() {
  return (
    <>
      <div className={classes.termsOfUseText}>
        <h4>TERMS OF SERVICE</h4>
        <div>
                  Effective Date: March 16, 2021
        </div>
        <div>
                    The terms of this agreement ("Terms of Service") govern the relationship between you and Jumpilot AB, a Swedish company (business ID 559280-8132) having its registered office at Anckargripsgatan 3, 211 19-SE Malmö, Sweden (hereinafter "Jumpilot" or "Us" or "We") regarding your use of Jumpilot's games, websites and related services (the "Service"). Use of the Service is also governed by Jumpilot's Privacy Policy and other relevant policies, which are incorporated herein by reference.
        </div>
        <div>
                    Before accessing or using the Service, including browsing any Jumpilot website or accessing a game, you must agree to these Terms of Service and the Privacy Policy.
        </div>
        <div>
                    You may also be required to register an account on the Service (an "Account"). By registering for an Account or otherwise using the Service, you affirm that you are the legal age of majority in your country of residence. If you are not, your legal guardian must review and agree to these Terms. If you access the Service from a Social Networking Site ("SNS"), such as Facebook or Google+, you shall comply with its terms of service/use as well as these Terms of Service.
        </div>
        <div>
                    BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.
        </div>
        <div>
                    Jumpilot reserves the right, at its discretion, to change, modify, add or remove portions of these Terms of Service,  its Privacy Policy and other relevant Jumpilot policies at any time by posting the amended terms on the Jumpilot Service. You will be deemed to have accepted such changes by continuing to use the Service. If at any point you do not agree to any portion of the then-current version of our Terms of Service, the Jumpilot Privacy Policy, or any other Jumpilot policy, rules or codes of conduct relating to your use of the Service, your license to use the Service shall immediately terminate, and you must immediately stop using the Service.
        </div>
        <div>
                    1. License
        </div>
        <div>1.1. Grant of a Limited License to Use the Service </div>
        <p>
                  Subject to your agreement and continuing compliance with these Terms of Service and any other relevant Jumpilot policies, Jumpilot grants you a non-exclusive, non-transferable, non-sublicensable, revocable and limited license to access and use the Service for your own non-commercial entertainment purposes. You agree not to use the Service for any other purpose.
                  The following restrictions apply to the use of the Service:
                  You accept full responsibility for any unauthorized use of the Service by minors. You are responsible for any use of your credit card or other payment instrument (e.g. paypal) by minors.
                  You shall not (or attempt to) purchase, sell, rent or give away your Account, create an Account using a false identity or information, or on behalf of someone other than yourself; You shall not use the Service if you have previously been removed by Jumpilot, or previously been banned from playing any Jumpilot game.
                  You shall use your Account only for non-commercial purposes; You shall not use the Service to advertise, or solicit, or transmit any commercial advertisements, including chain letters, junk or spam e-mail or repetitive or misleading messages to anyone.
                  Login information and Your Account
        </p>
        <p>
                  You may be required to select a password for your Account or you may also use other credentials to access the Account ("Login Information"). You shall not share the Account or the Login Information, nor let anyone else access your Account or do anything else that might jeopardize the security of your Account. In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Login Information, you must immediately notify Jumpilot and modify your Login Information. You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of the Login Information, including purchases, whether or not authorized by you. You are responsible for anything that happens through your Account.
                  Jumpilot reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that a username violates the third party's rights.
                  The Service supports only one Account per game on a supported device.
                  License Limitations
        </p>
        <p>
                    Any use of the Service in violation of these License Limitations is strictly prohibited, can result in the immediate revocation of your limited license and may subject you to liability for violations of law.
                    You agree that you will not, under any circumstances:
                    Engage in any act that Jumpilot deems to be in conflict with the spirit or intent of the Service or make improper use of Jumpilot s support services.
                    Use or take part (directly or indirectly) in the use of cheats, exploits, automation software, emulators, bots, hacks, mods or any unauthorized third-party software designed to modify or interfere with the Service, any Jumpilot game or any Jumpilot game experience.
                    Modify or cause to be modified any files that are a part of the Service or any Jumpilot game without Jumpilot 's express written consent.
                    Disrupt, interfere with or otherwise adversely affect the normal flow of the Service or otherwise act in a manner that may negatively affect other users' experience when using the Service or playing Jumpilot's games. This includes win trading and any other kind of manipulation of rankings, taking advantage of errors in the Service to gain an unfair edge over other players and any other act that intentionally abuses or goes against the design of the Service.
                    Disrupt, overburden, or aid or assist in the disruption or overburdening of any computer or server ("Server") used to offer or support the Service or any Jumpilot game environment.
                    Institute, assist, or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or any other person's use or enjoyment of the Service.
                    Attempt to gain unauthorized access to the Service, Accounts registered to others or to the computers, Servers, or networks connected to the Service by any means other than the user interface provided by Jumpilot, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Service.
                    Post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable or offensive or engage in ongoing toxic behavior, such as by repeatedly posting information on an unsolicited basis.
                    Post any information that contains nudity, excessive violence, or offensive subject matter or that contains a link to such content.
                    Attempt to, or harass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person, group, including Jumpilot employees, including Jumpilot s customer service representatives.
                    Make available through the Service any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or impersonates any other person, including without limitation a Jumpilot employee.
                    Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying software or other intellectual property used to provide the Service or any Jumpilot game, or to obtain any information from the Service or any Jumpilot game using any method not expressly permitted by Jumpilot.
                    Solicit or attempt to solicit Login Information or any other login credentials or personal information from other users of the Service or any Jumpilot game.
                    Collect or post anyone's private information, including personally identifiable information (whether in text, image or video form), identification documents, or financial information through the Service.
                    Use any Jumpilot game for gambling, betting or any similar activity in which prizes or rewards can be won (directly or indirectly), including betting on the outcome of matches in which you participate as a player, irrespective of whether or not there is a fee or stake involved.
                    Jumpilot reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms of Service or the Service itself. Jumpilot reserves the right to take action as a result, which may include terminating your Account and prohibiting you from using the Service in whole or in part.
        </p>
        <h5>1.2. Suspension and Termination of Account and Service </h5>
        <p>
                  WITHOUT LIMITING ANY OTHER REMEDIES, JUMPILOT MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS THEREOF IF YOU ARE, OR JUMPILOT SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH ANY OF THESE TERMS OF SERVICE OR FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE OF THE SERVICE, WITH OR WITHOUT NOTICE TO YOU. YOU CAN LOSE YOUR USER NAME AND PERSONA IN THE SERVICE AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED ITEMS AND PURCHASED ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICE, AND JUMPILOT IS UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.
                  WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR   POLICIES. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS.
                  JUMPILOT RESERVES THE RIGHT TO TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 180 DAYS.
                  Jumpilot reserves the right to stop offering and/or supporting the Service or a particular game or part of the Service at any time, at which point your license to use the Service or a part thereof will be automatically terminated. In such event, Jumpilot shall not be required to provide refunds, benefits or other compensation to users in connection with such discontinued Service
. Termination of your Account can include disabling your access to the Service or any part thereof including any content you submitted or others submitted.
                  You may terminate your Account at any time and for any reason by following the process located at https://jumpilot.com informing Jumpilot that you wish to terminate your Account.
        </p>
        <div>
                  2. Ownership
        </div>
        <div>
                  2.1. Games and Service
        </div>
        <div>
                 All rights, title and interest in and to the Service (including without limitation any games, titles, computer code, themes, objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information, recordings of games played using a Jumpilot game client, and the Jumpilot game clients and server software) are owned by Jumpilot. Jumpilot reserves all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with its games and the Service.
        </div>
        <div>
                  2.2. Accounts
        </div>
        <div>
                 NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN THE ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE BENEFIT OF JUMPILOT.
        </div>
        <div>
                  2.3. Virtual Items
        </div>
        <div>
                  Jumpilot owns, has licensed, or otherwise has rights to use all of the content that appears in the Service or in Jumpilot games. Notwithstanding any provision to the contrary herein, you agree that you have no right or title in or to any content that appears in the Service, including without limitation the virtual goods or currency appearing or originating in any Jumpilot game, whether earned in a game or purchased from Jumpilot, or any other attributes associated with an Account or stored on the Service.
        </div>
        <div>
                    3. User Content
        </div>
        <div>
                  3.1. Submission of User Content
        </div>
        <div>
                    "User Content" means any communications, images, sounds, and all the material, data, and information that you upload or transmit through a Jumpilot game client or the Service, or that other users upload or transmit, including without limitation any chat text. By transmitting or submitting any User Content while using the Service, you affirm, represent and warrant that such transmission or submission is (a) accurate and not confidential or misleading; (b) not in violation of any laws, contractual restrictions or other third party rights, and that you have permission from any third party whose personal information or intellectual property is comprised in the User Content; (c) free of viruses, adware, spyware, worms or other malicious code; and (d) you acknowledge and agree that any of your personal information within such content will at all times be processed by Jumpilot in accordance with its Privacy Policy.
        </div>
        <div>3.1.1. Content Screening</div>
        <div> Jumpilot assumes no responsibility for the conduct of any user submitting any User Content, and assumes no responsibility for monitoring the Service for inappropriate content or conduct. We do not, and cannot, pre-screen or monitor all User Content. Your use of the Service is at your own risk. By using the Service, you may be exposed to User Content that is offensive, indecent or otherwise not in line with your expectations. You bear all risks associated with the use of any User Content available in connection with the Service. At our discretion, our representatives or technology may monitor and/or record your interaction with the Service or communications (including without limitation chat text) when you are using the Service.
                 By entering into these Terms of Service, you hereby provide your irrevocable consent to such monitoring and recording. You acknowledge and agree that you have no expectation of privacy concerning the transmission of any User Content, including without limitation chat text or voice communications.
                 Jumpilot reserves the right in its sole discretion to review, monitor, prohibit, edit, delete, disable access to or otherwise make unavailable any User Content (including without limitation your User Content) without notice for any reason or for no reason at any time. If at any time Jumpilot chooses, in its sole discretion, to monitor the Service, Jumpilot nonetheless assumes no responsibility for User Content and assumes no obligation to modify or remove any inappropriate User Content. We have the right, but not the obligation, in our sole discretion to edit, refuse to post, or remove any User Content.
        </div>
        <div>3.2. Information Use by Other Members of the Service</div>
        <div> 3.2.1. Public Discourse</div>
        <div>
                 The Service may include various forums, blogs and chat features where you can post User Content, including your observations and comments on designated topics. Jumpilot cannot guarantee that other members will not use the ideas and information that you share. Therefore, if you have an idea or information that you would like to keep confidential and/or don't want others to use, do not post it on the Service. Jumpilot shall have no responsibility to evaluate, use or compensate you for any ideas or information you may choose to submit.
        </div>
        <div>3.2.2. Responsible For Your Own Content</div>
        <div>
                 You are solely responsible for the information that you post on, through or in connection with the Service and that you provide to others. Jumpilot may reject, refuse to post or delete any User Content for any or no reason, including, but not limited to, User Content that in the sole judgment of Jumpilot violates these Terms of Service.
        </div>
        <div>3.2.3. Your License to Jumpilot</div>
        <div>
                  You hereby grant to Jumpilot an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of the Service, including marketing and promotions of the Service. You also hereby grant to Jumpilot the right to authorize others to exercise any of the rights granted to Jumpilot under these Terms of Service. You further hereby grant to Jumpilot the unconditional, irrevocable right to use and exploit your name, likeness and any other information or material included in any User Content and in connection with any User Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner. Jumpilot does not claim any ownership rights in your User Content and nothing in these Terms of Service is intended to restrict any rights that you may have to use and exploit your User Content. Jumpilot has no obligation to monitor or enforce your intellectual property rights in or to your User Content.
        </div>
        <div>3.3. User Interactions</div>
        <div>You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service and/or Jumpilot games. Jumpilot reserves the right, but has no obligation, to become involved in any way with these disputes. You will fully cooperate with Jumpilot to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting Jumpilot access to any password-protected portions of your Account.
                  If you have a dispute with one or more users, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
        </div>
        <div>4. Fees and Purchase Terms</div>
        <div>4.1.Purchases</div>
        <div>In the Service you may purchase, with "real world" money, a limited, personal, non-transferable, non-sublicensable, revocable license to use (a) "virtual currency", including but not limited to virtual cash or diamonds, all for use in Jumpilot games; (b) "virtual in-game items" (together with "virtual currency", "Virtual Items"); and (c) other goods or services ("Merchandise"). You are only allowed to purchase Virtual Items from us or our authorised partners through the Service, and not in any other way.
                  Jumpilot may manage, regulate, control, modify or eliminate Virtual Items and/or Merchandise at any time, with or without notice. Jumpilot shall have no liability to you or any third party in the event that Jumpilot exercises any such rights.
                  The transfer of Virtual Items and Merchandise is prohibited except where expressly authorized in the Service. Other than as expressly authorized in the Service, you shall not sell, purchase, redeem or otherwise transfer Virtual Items or Merchandise to any person or entity or attempt any of the aforesaid, including but not limited to Jumpilot, another user or any third party.
                  ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND NON-REFUNDABLE.
                  The provision of Virtual Items for use in Jumpilot games is a service provided by Jumpilot that commences immediately upon acceptance by Jumpilot of your purchase.
        </div>
        <div>
                  In the Service you may purchase, with "real world" money, a limited, personal, non-transferable, non-sublicensable, revocable license to use (a) "virtual currency", including but not limited to virtual cash or diamonds, all for use in Jumpilot games; (b) "virtual in-game items" (together with "virtual currency", "Virtual Items"); and (c) other goods or services ("Merchandise"). You are only allowed to purchase Virtual Items from us or our authorised partners through the Service, and not in any other way.
                  Jumpilot may manage, regulate, control, modify or eliminate Virtual Items and/or Merchandise at any time, with or without notice. Jumpilot shall have no liability to you or any third party in the event that Jumpilot exercises any such rights.
                  The transfer of Virtual Items and Merchandise is prohibited except where expressly authorized in the Service. Other than as expressly authorized in the Service, you shall not sell, purchase, redeem or otherwise transfer Virtual Items or Merchandise to any person or entity or attempt any of the aforesaid, including but not limited to Jumpilot, another user or any third party.
                  ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND NON-REFUNDABLE.
                  The provision of Virtual Items for use in Jumpilot games is a service provided by Jumpilot that commences immediately upon acceptance by Jumpilot of your purchase.
        </div>
        <div>
                  4.2. Payment of Fees
        </div>
        <div>
                  You agree to pay all fees and applicable taxes incurred by you or anyone using an Account registered to you. Jumpilot may revise the pricing for the goods and services offered through the Service at any time. YOU ACKNOWLEDGE THAT JUMPILOT IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
        </div>
        <div>5. Updates to the Service</div>
        <div>
                  You understand that the Service is an evolving one. Jumpilot may require that you accept updates to the Service and to Jumpilot's games you have installed on your device or computer. You acknowledge and agree that Jumpilot may update the Service and Jumpilot games, with or without notifying you. You may need to update third party software from time to time in order to receive the Service and play Jumpilot games.
        </div>
        <div> 6. Disclaimer of Warranties</div>
        <div>
                  WITHOUT LIMITING JUMPILOT'S LIABILITY UNDER SECTION 7 BELOW, THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. JUMPILOT DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
Some jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above disclaimers may not apply to you.
        </div>
        <div>
                  7. Limitation of Liability; Sole and Exclusive Remedy; Indemnification
        </div>
        <div> TO THE MAXIMUM EXTENT PERMITTED BY LAW, JUMPILOT SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT JUMPILOT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, JUMPILOT SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID TO JUMPILOT IN ACCORDANCE WITH THESE TERMS OF SERVICE IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID ANYTHING TO JUMPILOT DURING SUCH TIME PERIOD, YOUR SOLE REMEDY (AND JUMPILOT 'S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH JUMPILOT IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.
                  NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF JUMPILOT OR FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF JUMPILOT.
                  You agree to indemnify, defend and hold Jumpilot (and our officers, directors, agents, subsidiaries, joint ventures and employees) harmless from any claim, demand, damages or other losses, including reasonable attorneys' fees, asserted by any third-party resulting from or arising out of your use of the Service, or any breach by you of these Terms of Service, however the foregoing does not apply if the infringement of rights is not attributable to your intentional or negligent behavior.
        </div>
        <div>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, JUMPILOT SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT JUMPILOT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, JUMPILOT SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID TO JUMPILOT IN ACCORDANCE WITH THESE TERMS OF SERVICE IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID ANYTHING TO JUMPILOT DURING SUCH TIME PERIOD, YOUR SOLE REMEDY (AND JUMPILOT 'S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH JUMPILOT IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.
                  NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF JUMPILOT OR FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF JUMPILOT.
        </div>
        <div>
                  You agree to indemnify, defend and hold Jumpilot (and our officers, directors, agents, subsidiaries, joint ventures and employees) harmless from any claim, demand, damages or other losses, including reasonable attorneys' fees, asserted by any third-party resulting from or arising out of your use of the Service, or any breach by you of these Terms of Service, however the foregoing does not apply if the infringement of rights is not attributable to your intentional or negligent behavior.
        </div>
        <div> 8. Dispute Resolution and Law</div>
        <div>
                  If a dispute arises between you and Jumpilot, we strongly encourage you to first contact us directly to seek a resolution by going to our customer support site at https://jumpilot com/. If you are a resident of the United States, these Terms of Service and any dispute arising out of or related to it or Privacy Policy or the Service shall be governed in all respects by California law, without regard to conflict of law provisions. You agree that any claim or dispute you may have against Jumpilot must be resolved exclusively by a court located in Malmo, Sweden.
        </div>
        <div>9. Severability</div>
        <div>
                  You and Jumpilot agree that if any portion of these Terms of Service or of the Jumpilot Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the terms, which shall continue to be in full force and effect.
        </div>
        <div>10. General Provisions</div>
        <div> 10.1. Assignment</div>
        <div>
                  Jumpilot may assign or delegate these Terms of Service and/or the Jumpilot Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without Jumpilot's prior written consent, and any unauthorized assignment and delegation by you is ineffective.
        </div>
        <div>
                  Jumpilot may assign or delegate these Terms of Service and/or the Jumpilot Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without Jumpilot's prior written consent, and any unauthorized assignment and delegation by you is ineffective.
        </div>
        <div>10.2. Supplemental Policies</div>
        <div>
                  Jumpilot may publish additional policies related to specific services such as forums, contests or loyalty programs. Your right to use such services is subject to those specific policies and these Terms of Service.
        </div>
        <div>10.3. Entire Agreement</div>
        <div>
                  These Terms of Service, any supplemental policies and any documents expressly incorporated by reference herein (including the Jumpilot Privacy Policy), contain the entire understanding of you and Jumpilot, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us with respect to the Service.
        </div>
        <div>10.4. No Waiver</div>
        <div>
                  The failure of Jumpilot to require or enforce strict performance by you of any provision of these Terms of Service or the Jumpilot Privacy Policy or failure to exercise any right under them shall not be construed as a waiver or relinquishment of Jumpilot’s right to assert or rely upon any such provision or right in that or any other instance.
                  The express waiver by Jumpilot of any provision, condition, or requirement of these Terms of Service or the Jumpilot Privacy Policy shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.
                  Except as expressly and specifically set forth in this these Terms of Service, no representations, statements, consents, waivers, or other acts or omissions by Jumpilot shall be deemed a modification of these Terms of Service nor legally binding, unless documented in physical writing, hand signed by You and a duly appointed officer of Jumpilot.
        </div>
        <div>10.5. Notices</div>
        <div>
                  We may notify you via postings on www.jumpilot.com, and via e-mail or any other communications means to contact information you provide to us. All notices given by you or required from you under these Terms of Service or the Jumpilot Privacy Policy shall be in writing and addressed to: Jumpilot  AB Attn: Legal,  Anckargripsgatan 3, 211 19-SE Malmö, Sweden. Any notices that you provide without compliance with this Section on Notices shall have no legal effect.
        </div>
        <div>10.6. Equitable Remedies</div>
        <div>You acknowledge that the rights granted and obligations made under these Terms of Service to Jumpilot are of a unique and irreplaceable nature, the loss of which shall irreparably harm Jumpilot and which cannot be replaced by monetary damages alone so that Jumpilot shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you.
                  You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any Jumpilot game, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by Section 7 (if any).
        </div>
        <div>10.7. Force Majeure</div>
        <div>
                 Jumpilot shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of Jumpilot, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond Jumpilot 's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
        </div>
      </div>
    </>
  );
}

export default TermsOfUse;