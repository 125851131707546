import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {checkQRData, resetQR, setQRData} from '../../store/actions/qr.actions';
import {useHistory} from 'react-router-dom';
import classes from './QRPath.module.css';
import JumpHeroezLogo from '../../images/JUMPILOT_LOGO.png';
import present from '../../images/present.png';
import Cookie from 'js-cookie';
import {cleaUserData} from '../../store/actions/dashboard.actions';
import {logout} from '../../store/actions/auth.actions';
import {checkPhoneNumber} from '../../store/actions/phoneNumber.actions';

function QRPath() {

  const qrData = window.location.href.split('/').splice(-1)[0]

  const dispatch = useDispatch()

  const history = useHistory()

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  const redirectToLoginPage = useSelector(state => state.qrReducer.redirectToLoginPage)

  const phoneNumberAlreadyExists = useSelector(state => state.phoneNumberReducer.phoneNumberAlreadyExists)

  useEffect(() => {
    Cookie.remove('AUTH-TOKEN')
    dispatch(cleaUserData())
    dispatch(resetQR())
    dispatch(logout())
  }, [])

  useEffect(() => {
    qrData && dispatch(setQRData(qrData))
  }, [qrData]);

  useEffect(() => {
    qrData && dispatch(checkQRData({qrData}))
  }, [qrData]);


  const goToLogin = () => {
    redirectToLoginPage && history.push('/')
  }

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/home')
      const token = Cookie.get('AUTH-TOKEN');
      dispatch(checkPhoneNumber(token))
      if (phoneNumberAlreadyExists === true){
        history.push('/home')
      } else if (phoneNumberAlreadyExists === false){
        history.push('/phone-number')
      }
    }
  }, [isLoggedIn])

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.logoContainer}>
        <img
          alt={'JumpHeroez Logo'}
          className={classes.jumpHeroezLogo}
          src={JumpHeroezLogo}
        />
      </div>
      <div className={classes.sectionWrapper}>
        <img
          alt={'reward'}
          className={classes.presentImg}
          src={present}
        />
        <div className={classes.textWrapper}>
          <div className={classes.header}>CONGRATULATIONS!</div>
          <div className={classes.subheader}>FANTASTIC ACHIEVEMENT.</div>
          <div className={classes.subheader}>COLLECT YOUR REWARD,</div>
          <div className={classes.subheader}>YOU DESERVE IT!</div>
          <button
            className={classes.button}
            onClick={goToLogin}
          >
            COLLECT REWARD
          </button>
        </div>
      </div>
    </div>
  );
}


export default QRPath;

