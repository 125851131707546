import jumpLogo from '../../images/JUMPILOT_LOGO.png'
import classes from './EndOfJumpHeroez.module.css'
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import img from '../../images/error-kenji.png';
import arrow from '../../images/arrow-left.png'
import React, {useEffect} from 'react';

function EndOfJumpHeroez() {

  const history = useHistory();

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/')
    }
  }, [isLoggedIn])

  const goBack = () => {
    history.push('/home')
  }

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.logoWrapper}>
        <img 
          alt={'jump heroez logo'}
          className={classes.logoImg}
          src={jumpLogo}
        />
      </div>
      <div className={classes.mainSection}>
        <div className={classes.leftColumn}>
          <img
            alt={'Jump Heroez'}
            className={classes.img}
            src={img}
          />
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.header}>
            OOPS! YOU HAVE REACHED THE END
          </div>
          <div className={classes.subHeader}>
            WE WORK TO MAKE IT AVAILABLE SOON
          </div>
          <button
            className={classes.button}
            onClick={goBack}
          >
            <img 
              alt={'arrow'}
              className={classes.arrow}
              src={arrow}
            />
            <div>
              BACK
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default EndOfJumpHeroez;