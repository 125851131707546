import React from 'react';
import classes from './LevelSection.module.css'
import {useSelector} from 'react-redux';

function LevelSection() {

  const dashboardData = useSelector(state => state.dashboardReducer.dashboardData)

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.levelHeader}>
        <div className={classes.levelWord}>LEVEL 1</div>
        <div className={classes.levelRating}>0/5000</div>
      </div>
      <div className={classes.levelProgressBar}>
        <div className={classes.levelProgressBarFilling}> </div>
        <div className={classes.nextLevel}>LEVEL 2</div>
      </div>
    </div>
  );
}

export default LevelSection;