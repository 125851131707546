import {
  CLEAR_USER_DATA,
  HIDE_DASHBOARD_ERROR,
  SET_DASHBOARD_DATA, SET_MAIN_REWARD,
  SHOW_DASHBOARD_ERROR
} from '../actions/dashboard.actions';

const initialState = {
  dashboardData: '',
  error: false,
  mainReward: ''
}

export const dashboardReducer = (state = initialState, action) => {
  switch(action.type){
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload
      }
    case CLEAR_USER_DATA:
      return {
        dashboardData: ''
      }
    case SHOW_DASHBOARD_ERROR:
      return {
        ...state,
        error: true
      }
    case HIDE_DASHBOARD_ERROR:
      return {
        ...state,
        error: false
      }
    case SET_MAIN_REWARD:
      return {
        ...state,
        mainReward: action.payload
      }
    default:
      return state
  }
}
