import React from 'react';
import classes from './Reward30Min.module.css';
import freeMinutes from '../../../images/30minutes.png'
import {useHistory} from 'react-router-dom';
import arrowLeft from '../../../images/arrow-left.png';

function Reward30Min() {

  const history = useHistory();

  const seeReward = () => {
    history.push('/reward-code')
  }

  const handleBackBtn = () => {
    history.push('/home')
  }

  return (
    <div className={classes.rewardContainer}>
      <div className={classes.firstBlock}>
        <div className={classes.header}>ADDITIONAL FREE</div>
        <div className={classes.header}>30 MINUTES</div>
        <div className={classes.subheader}>NEXT TIME YOU VISIT THE PARK</div>
      </div>
      <div className={classes.secondBlock}>
        <img
          alt={'30 minutes free'}
          className={classes.freeMinutes}
          src={freeMinutes}
        />
      </div>
      <div className={classes.thirdBlock}>
        <div className={classes.text}>
          REWARD ACTIVE 30 DAYS
        </div>
      </div>
      <div className={classes.fourthBlock}>
        <button
          className={classes.violetButton}
          onClick={handleBackBtn}
        >
          <img
            alt={'arrow down'}
            className={classes.arrowDown}
            src={arrowLeft}
          />
         BACK
        </button>
        <button
          className={classes.pinkButton}
          onClick={seeReward}
        >
          CLAIM REWARD
        </button>
      </div>
    </div>
  );
}

export default Reward30Min;