import { combineReducers } from 'redux';
import {authReducer} from './auth.reducer';
import {heroesReducer} from './heroes.reducer';
import {dashboardReducer} from './dashboard.reducer';
import {qrReducer} from './qr.reducer';
import {phoneNumberReducer} from './phoneNumber.reducer';


export const rootReducer = combineReducers({
  authReducer: authReducer,
  heroesReducer:heroesReducer,
  dashboardReducer: dashboardReducer,
  qrReducer: qrReducer,
  phoneNumberReducer: phoneNumberReducer
})
