import React from 'react';
import classes from './DashboardError.module.css';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {hideDashboardDataError} from '../../../store/actions/dashboard.actions';
import Cookie from 'js-cookie'
import {logout} from '../../../store/actions/auth.actions';
import img from '../../../images/error-kenji.png';
import jumpLogo from '../../../images/JUMPILOT_LOGO.png';
import arrow from '../../../images/arrow-left.png';

function DashboardError() {

  const history = useHistory();

  const dispatch = useDispatch();

  const handleBackBtn = () => {
    Cookie.remove('AUTH-TOKEN')
    dispatch(hideDashboardDataError())
    dispatch(logout())
    history.push('/')
  }

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.logoWrapper}>
        <img
          alt={'jump heroez logo'}
          className={classes.logoImg}
          src={jumpLogo}
        />
      </div>
      <div className={classes.mainSection}>
        <div className={classes.leftColumn}>
          <img
            alt={'Jump Heroez'}
            className={classes.img}
            src={img}
          />
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.header}>
            OOPS!
          </div>
          <div className={classes.subHeader}>
           SOMETHING DIDN'T WORK
          </div>
          <button
            className={classes.button}
            onClick={handleBackBtn}
          >
            <img
              alt={'arrow'}
              className={classes.arrow}
              src={arrow}
            />
            <div>
              BACK
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardError;